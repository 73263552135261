import { isEqual } from 'lodash';

export function isDifferent<T>(
  // tslint:disable-next-line
  target: Object,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor,
): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: T[]) {
    const value = args[0];
    const prevValue = args[1];
    if (value === prevValue) {
      return;
    }

    // continue invoking the method
    return method.apply(this, args);
  };
  return propertyDesciptor;
}

export function isDifferentDeep<T>(
  // tslint:disable-next-line
  target: Object,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor,
): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: T[]) {
    const value = args[0];
    const prevValue = args[1];
    if (isEqual(value, prevValue)) {
      return;
    }

    // continue invoking the method
    return method.apply(this, args);
  };
  return propertyDesciptor;
}

export function isTruthy<T>(
  // tslint:disable-next-line
  target: Object,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor,
): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: T[]) {
    const value = args[0];
    if (!value) {
      return;
    }

    // continue invoking the method
    return method.apply(this, args);
  };
  return propertyDesciptor;
}

export function isFalsy<T>(
  // tslint:disable-next-line
  target: Object,
  propertyName: string,
  propertyDesciptor: PropertyDescriptor,
): PropertyDescriptor {
  const method = propertyDesciptor.value;

  propertyDesciptor.value = function(...args: T[]) {
    const value = args[0];
    if (value) {
      return;
    }

    // continue invoking the method
    return method.apply(this, args);
  };
  return propertyDesciptor;
}

export function isFiltered<T>(cb: (v: T, prevV: T) => boolean) {
  // tslint:disable-next-line
  return function (
    // tslint:disable-next-line
    target: Object,
    propertyName: string,
    propertyDesciptor: PropertyDescriptor,
  ): PropertyDescriptor {
    const method = propertyDesciptor.value;

    propertyDesciptor.value = function(...args: T[]) {
      const value = args[0];
      const prevValue = args[1];

      const passed = cb(value, prevValue);

      if (!passed) {
        return;
      }

      // continue invoking the method
      return method.apply(this, args);
    };
    return propertyDesciptor;
  };
}
