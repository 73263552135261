





























































import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { SortOrder } from '@/jbi-shared/types/search.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  GetGroupUserAttributeListPayload,
  GetGroupUserAttributeListResponse
} from '@/store/modules/admin/types/group-user-attribute.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import CreateAttributeStepper from '@/views/AdminUserManagement/components/create-attribute/CreateAttributeStepper.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import GroupUserAttributeList from './components/GroupUserAttributeList.vue';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc
  }
})
export default class AttributeTab extends Vue {
  public perPage: number = 50;
  public page: number = 1;
  public sortColumn: string = 'attributeName';
  public sortOrder: SortOrder = SortOrder.ASC;
  public filterInput: string = '';

  @Action('admin/getGroupUserAttributeList')
  public getGroupUserAttributeList!: (
    params: GetGroupUserAttributeListPayload
  ) => void;

  @State(({ admin }: RootState) => admin.groupUserAttributeList)
  public groupUserAttributeList!: GetGroupUserAttributeListResponse;

  @State(({ admin }: RootState) => admin.apiState.getGroupUserAttributeList)
  public getGroupUserAttributeListStatus!: ApiState;

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  public groupTypesUserHasAccessTo!: string[];

  @State((state: RootState) => state.rbac.groupsUserHasAccessTo)
  public groupsUserHasAccessTo!: number[];

  get GroupUserAttributeList(): typeof GroupUserAttributeList {
    return GroupUserAttributeList;
  }

  get isGroupViewAllowed() {
    return this.checkForPermissions(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-read_groups',
      true
    );
  }

  public checkForPermissions(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypesUserHasAccessTo
      ? this.groupTypesUserHasAccessTo[0]
      : undefined;
    const groupId = this.groupsUserHasAccessTo
      ? this.groupsUserHasAccessTo[0]
      : undefined;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      groupId,
      undefined,
      skipImplicitCheck
    );
  }

  public handleSort(sortCriteria: {
    sortColumn: string;
    sortOrder: SortOrder.ASC;
  }): void {
    this.sortColumn = sortCriteria.sortColumn;
    this.sortOrder = sortCriteria.sortOrder;

    const params = {
      attributeLabel:
        (this.$route.query.attributeLabel as string) || this.filterInput,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    this.getGroupUserAttributeList(params);
  }

  public handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }): void {
    if (this.$route.query.tab !== 'Attributes') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      attributeLabel:
        (this.$route.query.attributeLabel as string) || this.filterInput,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
    };

    this.getGroupUserAttributeList(params);
  }

  @Debounce(600)
  public handleFilter(): void {
    const params = {
      attributeLabel: this.filterInput,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder: (this.$route.query.sortOrder as SortOrder) || this.sortOrder
    };

    this.getGroupUserAttributeList(params);
  }

  public handleCreateAttribute(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateAttributeStepper,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {},
      events: {
        onCreateAttribute: () => this.routeParam()
      }
    });
  }

  public mounted(): void {
    if (this.$route.query.tab === 'Attributes') {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Attributes',
          attributeLabel:
            (this.$route.query.attributeLabel as string) || this.filterInput,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as SortOrder) || this.sortOrder
        }
      });
      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.filterInput =
        (this.$route.query.attributeLabel as string) || this.filterInput;

      this.handlePaginator({ perPage: this.perPage, page: 1 });
    }
  }

  @Watch('getGroupUserAttributeListStatus.success')
  @isDifferent
  @isTruthy
  public getGroupUserAttributeListSuccess() {
    this.$emit('activateTab');
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Attributes',
        attributeLabel: this.filterInput,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  @Watch('getGroupUserAttributeListStatus.error')
  @isDifferent
  @isTruthy
  public getGroupUserAttributeListError() {
    this.$emit('activateTab');
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Attributes') {
      this.handlePaginator({ perPage: this.perPage, page: 1 });
    }
  }
}
