
































































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';
import {
  FilteredGroupPayload,
  GroupType
} from '../../../../store/modules/admin/types/admin.types';
import { PermissionsMatrixActionsEnum } from '../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '../../../../store/store';

import { isUserAllowed } from '../../../../utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class SelectGroupSearch extends Vue {
  @Prop(Object) public filteredGroupParams!: FilteredGroupPayload;
  @Prop(String) public labelName!: string;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  @Watch('filteredGroupParams', { deep: true })
  @Debounce(600)
  public onChange(): void {
    this.$emit('input', this.filteredGroupParams);
  }

  public handleFilter(): void {
    this.$emit('input', this.filteredGroupParams);
  }

  get typeOptions():
    | Array<{
        id: number;
        name: string;
        slug: string;
      }>
    | undefined {
    if (this.groupTypes) {
      const types = Object.values(this.groupTypes).map(
        (groupType: GroupType) => ({
          id: groupType.id,
          slug: groupType.name,
          name: groupType.name
        })
      );
      return [{ id: 0, name: 'All', slug: '' }, ...types];
    }
  }

  public resetFilter(): void {
    this.$emit('resetFilter');
    return;
  }
}
