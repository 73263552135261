




























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import ProductList from '@/views/AdminGroupManagement/components/ProductList.vue';
import { Action, State } from 'vuex-class';
import { RootState } from '../../store/store';
import ProductFormModal from '@/views/AdminGroupManagement/components/ProductFormModal.vue';
import {
  CreateGroupProductRequestPayload,
  UpdateGroupProductRequestPayload,
  GetGroupsProductResponsePayload,
  GroupProduct
} from '../../store/modules/admin/types/group-product.types';
import { ToastProgrammatic } from 'buefy';
import ProductSearch from './components/ProductSearch.vue';
import {
  FilteredGroupProductsPayload,
  GetGroupProductsFilterPayload,
  GetGroupProductsPayload,
  GetGroupProductsPayloadSort
} from '@/store/modules/admin/types/admin.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { ApiState } from '@/store/types/general.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { PermissionsMatrixActionsEnum } from '../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../utils/rbac.util';

const initialState = {
  productName: ''
};

const sortColumnList = () => ['products', 'applications'];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    ProductList,
    ProductFormModal,
    ProductSearch
  }
})
export default class ProductTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'title';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredGroupProductsParams: FilteredGroupProductsPayload = {
    ...initialState
  };

  @Action('admin/getGroupProducts')
  getGroupProducts!: (
    options: Partial<GetGroupProductsPayload>
  ) => Promise<GetGroupsProductResponsePayload>;

  @Action('admin/createGroupProduct')
  createGroupProduct!: (
    options: CreateGroupProductRequestPayload
  ) => Promise<GroupProduct>;

  @Action('admin/updateGroupProduct')
  updateGroupProduct!: (
    options: UpdateGroupProductRequestPayload
  ) => Promise<GroupProduct>;

  @State(({ admin }: RootState) => admin.apiState.getGroupProducts)
  public getGroupProductsStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groupProducts)
  public groupProducts!: GetGroupsProductResponsePayload;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public async getGroupProductList(params: GetGroupProductsPayload) {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-products-read_products'
      )
    ) {
      await this.getGroupProducts(params);
    }
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: GetGroupProductsFilterPayload = {
      productName: this.$route.query.productName as string
    };

    if (
      JSON.stringify(queryFilter) !==
      JSON.stringify(this.filteredGroupProductsParams)
    ) {
      this.page = 1;

      await this.getGroupProductList({
        productName: this.filteredGroupProductsParams.productName,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      });

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Products',
          productName: this.filteredGroupProductsParams.productName!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
    }
  }

  public async handleSort(data: GetGroupProductsPayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      productName:
        (this.$route.query.productName as string) ||
        this.filteredGroupProductsParams.productName,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getGroupProductList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Products',
        productName:
          this.$route.query.productName ||
          this.filteredGroupProductsParams.productName!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Products') {
      return;
    }

    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    await this.getGroupProductList({
      productName:
        (this.$route.query.productName as string) ||
        this.filteredGroupProductsParams.productName,
      limit: this.perPage,
      page: this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Products',
        productName:
          this.$route.query.productName ||
          this.filteredGroupProductsParams.productName!,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  get ProductList() {
    return ProductList;
  }

  openCreateProductModal() {
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: ProductFormModal,
      hasModalCard: true,
      trapFocus: true,
      events: {
        submit: async (v: CreateGroupProductRequestPayload) => {
          await this.handleCreateProduct(v);
          close();
        }
      }
    });
  }

  openEditProductModal(p: GroupProduct) {
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: ProductFormModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        name: p.name,
        id: p.id,
        applicationId: p.applicationId
      },
      events: {
        submit: async (v: UpdateGroupProductRequestPayload) => {
          await this.handleEditProduct(v);
          close();
        }
      }
    });
  }

  async handleCreateProduct(values: CreateGroupProductRequestPayload) {
    try {
      await this.createGroupProduct(values);

      this.handleTabResetQuery();
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async handleEditProduct(values: UpdateGroupProductRequestPayload) {
    try {
      await this.updateGroupProduct(values);
      this.handleTabResetQuery();
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public mounted() {
    if (this.$route.query.tab === 'Products') {
      this.filteredGroupProductsParams = {
        productName:
          (this.$route.query.productName as string) ||
          this.filteredGroupProductsParams.productName
      };

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Products',
          productName:
            (this.$route.query.productName as string) ||
            this.filteredGroupProductsParams.productName!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn: sortColumnList().includes(
            this.$route.query.sortColumn as string
          )
            ? (this.$route.query.sortColumn as string) || this.sortColumn
            : this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });

      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    }
  }
  public handleResetQuery() {
    this.getGroupProductList({
      productName:
        (this.$route.query.productName as string) ||
        this.filteredGroupProductsParams.productName,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  public async handleTabResetQuery() {
    this.filteredGroupProductsParams = { ...initialState };

    await this.getGroupProductList({
      page: this.page,
      limit: this.perPage,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Products') {
      this.handleTabResetQuery();
    }
  }

  @Watch('getGroupProductsStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getGroupProductsStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getGroupProductsStatus.error')
  @isDifferent
  @isTruthy
  public getGroupProductsStatusError() {
    this.$emit('activateTab');
  }
}
