































import { Component, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import {
  FilteredGroupTypePayload,
  GetGroupTypesPayload
} from '../../store/modules/admin/types/group-type.types';
import { Action, State } from 'vuex-class';
import { RootState } from '../../store/store';
import { ApiState } from '../../store/types/general.types';
import {
  GroupType,
  Pagination
} from '../../store/modules/admin/types/admin.types';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import GroupTypeList from './components/GroupTypeList.vue';
import GroupTypeModal from './components/GroupTypeModal.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { get as _get } from 'lodash';
import { PermissionsMatrixActionsEnum } from '../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../utils/rbac.util';

const initialState = {
  groupTypeName: ''
};

const sortColumnList = () => ['groupType', 'masterGroups', 'subGroups'];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    GroupTypeList,
    GroupTypeModal
  }
})
export default class GroupTypeTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'groupType';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredGroupTypeParams: FilteredGroupTypePayload = {
    ...initialState
  };
  public mode: string | null = null;

  @Action('admin/getGroupTypes')
  getGroupTypes!: (options: GetGroupTypesPayload) => void;

  @Action('admin/editGroupTypeById')
  editGroupTypeById!: (payload: GroupType) => void;

  @Action('admin/createGroupType')
  createGroupType!: (payload: string) => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupTypes)
  public getGroupTypesStatus!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.groupType)
  public groupTypeStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: Pagination<GroupType>;

  get isAllowedToCreateGroupTypes(): boolean {
    return (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-group_types-create_group_types'
      ) &&
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    );
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public async getGroupTypesList(params: GetGroupTypesPayload) {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes(params);
    }
  }

  public mounted() {
    if (this.$route.query.tab === 'GroupType') {
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'GroupType',
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn: sortColumnList().includes(
            this.$route.query.sortColumn as string
          )
            ? (this.$route.query.sortColumn as string) || this.sortColumn
            : this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    } else {
      this.getGroupTypesList({
        limit: +this.$route.query.limit || this.perPage,
        page: +this.$route.query.page || this.page,
        sortColumn: sortColumnList().includes(
          this.$route.query.sortColumn as string
        )
          ? (this.$route.query.sortColumn as string) || this.sortColumn
          : this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      });
    }
  }

  get GroupTypeList() {
    return GroupTypeList;
  }

  public handleResetQuery() {
    this.getGroupTypesList({
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'GroupType') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;
    const params = {
      limit: this.perPage,
      page: this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };

    this.getGroupTypesList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'GroupType',
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    this.getGroupTypesList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'GroupType',
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  @Watch('getGroupTypesStatus.success')
  @isTruthy
  public getGroupTypesStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getGroupTypesStatus.error')
  @isTruthy
  public getGroupTypesStatusError() {
    this.$emit('activateTab');
  }

  @Watch('groupTypeStatus.success')
  @isTruthy
  public groupTypeStatusSuccess() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `${
        this.mode === 'edit' ? 'Changes Saved' : 'Group Type created'
      }`
    });
    this.mode = null;
    this.handleResetQuery();
  }

  @Watch('groupTypeStatus.error')
  @isTruthy
  public groupTypeStatusError() {
    Toast.open({
      message: _get(
        this.groupTypeStatus.error,
        'response.data.message',
        'Unknown Error'
      ),
      position: 'is-top',
      type: 'is-danger'
    });
    this.mode = null;
  }

  openCreateGroupTypeModal() {
    this.$buefy.modal.open({
      parent: this,
      component: GroupTypeModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Create Group Type',
        groupTypeItem: null
      },
      events: {
        confirmCreate: (name: string) => {
          this.mode = 'create';
          this.createGroupType(name);
        }
      }
    });
  }

  openEditGroupTypeModal(item: GroupType) {
    this.$buefy.modal.open({
      parent: this,
      component: GroupTypeModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        modalTitle: 'Edit Group Type',
        groupTypeItem: item
      },
      events: {
        confirmEdit: (editedItem: GroupType) => {
          this.mode = 'edit';
          this.editGroupTypeById(editedItem);
        }
      }
    });
  }
}
