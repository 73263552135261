




































































































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { Group } from '@/store/modules/admin/types/admin.types';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { RootState } from '@/store/store';
import GroupRow from './GroupRow.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import { JAAS_APP, JAAS_APP_DETAIL } from '@/jbi-shared/types/jaas-app.types';
import dayjs from 'dayjs';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { GroupLicenseStatusEnum } from '@/enums/group-license-status.enum';
import { PermissionsMatrixActionsEnum } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../utils/rbac.util';

@Component({
  computed: {
    GroupLicenseStatusEnum() {
      return GroupLicenseStatusEnum;
    },
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    GroupRow,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class ProductList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'License', sortColumn: 'license', sort: true },
    { placeholder: 'Products', sortColumn: 'product', sort: true },
    { placeholder: 'Groups', sortColumn: 'group', sort: true },
    { placeholder: 'Expiry Date', sortColumn: 'expiryDate', sort: true },
    { placeholder: 'Seats', sortColumn: 'seats', sort: true },
    { placeholder: 'Status', sort: false }
  ];
  @Prop() public items!: Group[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  @State(({ admin }: RootState) => admin.apiState.getGroupProducts.success)
  public getGroupProductsSuccess!: boolean;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    return isUserAllowed(
      action,
      module,
      undefined,
      undefined,
      undefined,
      undefined,
      skipImplicitCheck
    );
  }

  public formatDateToReadable(date: Date) {
    return dayjs(date).format('D MMMM YYYY');
  }
  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }

  getAppName(appId: JAAS_APP) {
    return JAAS_APP_DETAIL[appId].name;
  }
}
