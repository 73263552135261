































































































import draggable from 'vuedraggable';
import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { RootState } from '../../../../store/store';
import AttributeWrapper from './AttributeWrapper.vue';
import { ApiState } from '../../../../store/types/general.types';
import CreateUserAttribute from '../../../AdminCreateGroup/components/UserAttributeTab/CreateNewAttribute.vue';
import DraggableIcon from '../../../AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';

import {
  clone,
  debouncer
} from '../../../../jbi-shared/util/group-level-attributes.util';
import {
  GroupUserAttributeType,
  MyjbiGroupUserAttributeSpec
} from '../../../../jbi-shared/types/myjbi-group.types';
import {
  AttributeDetails,
  GroupUserAttribute,
  GroupUserAttributeTemplate,
  NewGroupUserAttributeTemplatePayload,
  UpdateGroupUserAttributeTemplatePayload
} from '../../../../store/modules/admin/types/group-user-attribute-template.types';
import { GroupUserAttributeWithGroupCount } from '../../../../store/modules/admin/types/group-user-attribute.types';
import EditAttributeModal from '../EditAttributeModal.vue';
import ExistingUserAttributesModal from '@/views/components/PaginatedUserAttributes/ExistingUserAttributesModal.vue';

// HACK: this interface is a quickfix to address the nested structure
interface CreatedAttribute {
  groupUserAttribute: GroupUserAttribute;
  // specs from attribute creation modal
  required: boolean;
  lockAttribute: boolean;
  isDefault: boolean;
}

@Component({
  components: {
    draggable,
    AttributeWrapper,
    DraggableIcon,
    CreateUserAttribute,
    EditAttributeModal
  }
})
export default class EditUserAttributeTemplateModal extends Vue {
  @Prop() template!: GroupUserAttributeTemplate;
  @Prop() isUpdate!: boolean;

  isSubmitLoading: boolean = false;
  isTemplateNameVerifying: boolean = false;
  templateNameError: boolean = this.template.name === '';
  templateNameErrorMsg: string = '';
  templateNameMsgType: string = '';
  shouldUpdateListingOnClose: boolean = false;
  copyOfTemplateName: string = ''; // store an original copy of template name

  @State(({ admin }: RootState) => admin.groupUserAttributeTypes)
  public allGroupUserAttributeTypes!: GroupUserAttributeType[] | undefined;

  @Action('admin/createNewGroupUserAttributeTemplate')
  createNewGroupUserAttributeTemplate!: (
    payload: NewGroupUserAttributeTemplatePayload
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.createGroupUserAttributeTemplate
  )
  createGroupUserAttributeTemplateApiState!: ApiState;

  @State(({ admin }) => admin.newGroupUserAttributeTemplateId)
  newTemplateId!: number;

  @Action('admin/updateGroupUserAttributeTemplate')
  updateGroupUserAttributeTemplate!: (
    payload: UpdateGroupUserAttributeTemplatePayload
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.updateGroupUserAttributeTemplate
  )
  updateGroupUserAttributeTemplateApiState!: ApiState;

  @Action('admin/verifyGroupUserAttributeTemplateName')
  verifyTemplateName!: (templateName: string) => void;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.verifyGroupUserAttributeTemplateName
  )
  verifyTemplateNameApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupUserAttributeTemplateExisted)
  templateExisted!: boolean;

  mounted() {
    this.resetNameFieldValidation();
    // save template name on mount
    this.copyOfTemplateName = this.template.name;
  }

  /**
   * Returns boolean to control button state.
   */
  hasFormError(): boolean {
    if (this.template.name.trim() === '') {
      return true;
    }

    if (this.isTemplateNameVerifying === true) {
      return true;
    }

    // if template name already existed
    if (this.templateNameError === true) {
      return true;
    }

    if (this.template.attributes.length === 0) {
      return true;
    }

    return false;
  }

  handleTemplateNameChange(): void {
    if (this.template.name === '') {
      this.templateNameError = true;
      this.templateNameErrorMsg = 'Template name cannot be empty.';
      this.templateNameMsgType = 'is-danger';
      return;
    }

    this.resetNameFieldValidation();

    // reset field states if template name is restored
    if (this.template.name === this.copyOfTemplateName) {
      return;
    }

    this.isTemplateNameVerifying = true;
    debouncer(
      800,
      this.verifyTemplateName,
      this.template.name.trim().toLowerCase()
    );
  }

  resetNameFieldValidation(): void {
    this.templateNameError = false;
    this.templateNameErrorMsg = '';
    this.templateNameMsgType = '';
  }

  handleAttributeDrag(): void {
    this.updateAttributeOrdering();
  }

  updateAttributeOrdering(): void {
    this.template.attributes.forEach((attribute, index) => {
      attribute.order = index + 1;
    });
  }

  closeModal(): void {
    this.template.attributes = [];
    this.$emit('close');

    if (this.shouldUpdateListingOnClose) {
      this.$emit('updateAttributeList');
    }
  }

  handleSubmit(): void {
    this.isSubmitLoading = true;

    if (this.isUpdate) {
      const updateParams = {
        templateId: this.template.id,
        templateName: this.template.name,
        attributeDetails: this.sanitizeAttributesAsPayload(
          this.template.attributes
        )
      };
      this.updateGroupUserAttributeTemplate(updateParams);
      return;
    }

    this.createNewGroupUserAttributeTemplate({
      templateName: this.template.name,
      attributeDetails: this.sanitizeAttributesAsPayload(
        this.template.attributes
      )
    });
  }

  handleAttributeRemove(attributeIndex: number): void {
    this.template.attributes.splice(attributeIndex, 1);
    this.updateAttributeOrdering();
  }

  handleAttributeEdit(attributeToEdit: GroupUserAttribute): void {
    this.$buefy.modal.open({
      parent: this,
      component: EditAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        attributeId: attributeToEdit.id
      },
      events: {
        attributeUpdateSuccess: (
          updatedAttribute: GroupUserAttributeWithGroupCount
        ): void => {
          // existing attribute in the list needs to be updated
          attributeToEdit.name = updatedAttribute.name;
          attributeToEdit.option = updatedAttribute.option;
          this.shouldUpdateListingOnClose = true;
        }
      }
    });
  }

  public getUniqAttributes(
    attributes: MyjbiGroupUserAttributeSpec[]
  ): MyjbiGroupUserAttributeSpec[] {
    return [
      ...new Map(
        attributes.map((attribute) => [
          attribute.groupUserAttribute.id,
          attribute
        ])
      ).values()
    ];
  }

  transformTemplateAttributes() {
    return this.template.attributes.map((attribute) => {
      return {
        groupUserAttribute: {
          id: attribute.id,
          name: attribute.name,
          option: attribute.option,
          groupUserAttributeType: attribute.groupUserAttributeType
        },
        lockAttribute: attribute.lockAttribute,
        required: attribute.required
      };
    });
  }

  openAddUserAttributesModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: ExistingUserAttributesModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      fullScreen: true,
      props: {
        selectedAttributes: this.transformTemplateAttributes()
      },
      events: {
        updateSelection: (updatedAttributes: MyjbiGroupUserAttributeSpec[]) => {
          const mappedAttributes = updatedAttributes.map((attr, index) => {
            return {
              id: attr.groupUserAttribute.id,
              name: attr.groupUserAttribute.name,
              required: attr.required,
              lockAttribute: attr.lockAttribute as boolean,
              groupUserAttributeType:
                attr.groupUserAttribute.groupUserAttributeType,
              option: attr.groupUserAttribute.option,
              order: index + this.template.attributes.length + 1
            };
          });

          this.template.attributes = clone(mappedAttributes);
        }
      }
    });
  }

  handleAddNewAttribute(): void {
    this.$buefy.modal.open({
      parent: this,
      component: CreateUserAttribute,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      events: {
        addNewAttribute: (newAttribute: CreatedAttribute) => {
          const { required, lockAttribute, groupUserAttribute } = newAttribute;

          this.template.attributes.push({
            id: groupUserAttribute.id,
            name: groupUserAttribute.name,
            option: groupUserAttribute.option,
            groupUserAttributeType: groupUserAttribute.groupUserAttributeType,
            required,
            lockAttribute
          });

          this.updateAttributeOrdering();

          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `New attribute added`
          });

          this.shouldUpdateListingOnClose = true;
        }
      }
    });
  }

  sanitizeAttributesAsPayload(
    attributes: GroupUserAttribute[]
  ): AttributeDetails[] {
    return attributes.map<AttributeDetails>((attribute) => ({
      attributeId: attribute.id,
      required: attribute.required,
      lockAttribute: attribute.lockAttribute
    }));
  }

  @Watch('verifyTemplateNameApiState', { deep: true })
  verifyTemplateNameApiStateCallback(verifyState: ApiState): void {
    this.isTemplateNameVerifying = false;

    if (verifyState.success) {
      if (this.templateExisted) {
        this.templateNameError = true;
        this.templateNameErrorMsg = 'Template name already existed.';
        this.templateNameMsgType = 'is-danger';
      } else {
        this.templateNameError = false;
        this.templateNameErrorMsg = 'Template name is available.';
        this.templateNameMsgType = 'is-success';
      }
    }

    if (verifyState.error) {
      this.templateNameError = true;
      this.templateNameErrorMsg = verifyState.error.message;
      this.templateNameMsgType = 'is-danger';
    }
  }

  @Watch('updateGroupUserAttributeTemplateApiState', { deep: true })
  templateUpdateApiState(apiState: ApiState) {
    this.isSubmitLoading = false;

    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template updated successfully.`
      });

      this.$emit('updateSuccess', this.template.id);
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template update. Please try again.`);
    }
  }

  @Watch('createGroupUserAttributeTemplateApiState', { deep: true })
  templateCreateApiState(apiState: ApiState) {
    this.isSubmitLoading = false;

    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template created successfully.`
      });

      this.$emit('createSuccess', this.newTemplateId);
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template creation. Please try again.`);
    }
  }
}
