





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  FilteredGetGroup,
  FilteredGroupPayload,
  GetGroupsResponsePayload,
  Group,
  GROUP_LISTING_SCOPE,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import SelectGroupList from './select-group-list/SelectGroupList.vue';
import SelectGroupSearch from './SelectGroupSearch.vue';
import { cloneDeep } from 'lodash';
import SelectedGroupField from './SelectedGroupField.vue';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    SelectGroupList,
    SelectGroupSearch,
    SelectedGroupField
  }
})
export default class SelectGroupStep extends Vue {
  @Prop(String) public labelName!: string;
  @Prop(Array) public initialSelectedGroups!: Group[];
  @Prop(Number) public attributeId!: number;
  @Prop() public scope!: GROUP_LISTING_SCOPE;

  public perPage: number = 50;
  public page: number = 1;
  public sortColumn: string = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredGroupParams: FilteredGetGroup = { ...this.initialState };
  public lastFilterParams: FilteredGetGroup = { ...this.initialState };
  public expandGroupFilter = false;
  public groupStored: Group[] = [];
  public selectedGroupIds: number[] = [];
  public groupsToDisable: number[] = [];

  @Action('admin/getGroups')
  getGroups!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroups)
  public getGroupsStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groups)
  public groups!: Pagination<Group>;

  @State(({ admin }: RootState) => admin.groupsFromAttribute)
  public groupsFromAttribute!: Pagination<Group>;

  public handleResetFilter(): void {
    this.filteredGroupParams = { ...this.initialState };
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  get SelectGroupList(): typeof SelectGroupList {
    return SelectGroupList;
  }

  get initialState(): {
    groupname: string;
    grouptype: string;
  } {
    return {
      groupname: '',
      grouptype: ''
    };
  }

  get selectedGroups(): Group[] {
    return this.groupStored.filter(
      (group) => this.selectedGroupIds.indexOf(group.id) !== -1
    );
  }

  get formattedGroups(): Pagination<Group> {
    return this.attributeId && this.groups
      ? {
          ...this.groups,
          items: this.filterGroupByAncestors(this.groups.items)
        }
      : this.groups;
  }

  public updateSelectedGroup(selectedGroupIds: number[]): void {
    this.selectedGroupIds = selectedGroupIds;
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    this.perPage = perPage || 50;
    this.page = page || 1;

    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
    await this.getGroups({
      groupname: this.filteredGroupParams.groupname,
      grouptype: this.filteredGroupParams.grouptype,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      attributeId: this.isAttributeIdIncludedInParams()
        ? this.attributeId
        : null,
      scope: this.scope
    });

    this.expandGroupFilter = false;
    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
  }

  public async handleSort(data: {
    sortColumn: string;
    sortOrder: 'ASC' | 'DESC';
  }): Promise<void> {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      groupname: this.filteredGroupParams.groupname,
      grouptype: this.filteredGroupParams.grouptype,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      attributeId: this.isAttributeIdIncludedInParams()
        ? this.attributeId
        : null,
      scope: this.scope
    };

    await this.getGroups(params);
  }

  public async handleFilter(): Promise<void> {
    if (
      JSON.stringify(this.lastFilterParams) !==
      JSON.stringify(this.filteredGroupParams)
    ) {
      this.page = 1;
      this.expandGroupFilter = false;

      setTimeout(async () => {
        await this.getGroups({
          groupname: this.filteredGroupParams.groupname,
          grouptype: this.filteredGroupParams.grouptype,
          limit: this.perPage,
          page: 1,
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
          attributeId: this.isAttributeIdIncludedInParams()
            ? this.attributeId
            : null,
          scope: this.scope
        });

        this.expandGroupFilter = true;
        if (
          this.filteredGroupParams.groupname?.length === 0 &&
          this.filteredGroupParams.grouptype?.length === 0
        ) {
          this.expandGroupFilter = false;
        }
        this.lastFilterParams = cloneDeep(this.filteredGroupParams);
      }, 800);
    }
  }

  public mounted(): void {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes();
    }

    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
    this.handleResetQuery();
    this.expandGroupFilter = false;
    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
  }

  public handleResetQuery(): void {
    this.getGroups({
      groupname: this.filteredGroupParams.groupname,
      grouptype: this.filteredGroupParams.grouptype,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder,
      attributeId: this.isAttributeIdIncludedInParams()
        ? this.attributeId
        : null,
      scope: this.scope
    });
  }

  public filterGroupByAncestors(groups: Group[]): Group[] {
    const groupWithAncestors: Group[] = [];
    groups.forEach((group) => {
      if (group.nlevel === 1) {
        return groupWithAncestors.push(group);
      }
      const ancestorPath = group.path.split('' + group.id)[0];
      if (
        groups.filter((groupItem) => groupItem.path + '.' === ancestorPath)
          .length
      ) {
        return groupWithAncestors.push(group);
      }
      groupWithAncestors.push({ ...group, nlevel: 1 });
    });
    return this.getUniqGroups(groupWithAncestors);
  }

  public getUniqGroups(groups: Group[]): Group[] {
    return [...new Map(groups.map((group) => [group.id, group])).values()];
  }

  public isAttributeIdIncludedInParams() {
    return this.scope === GROUP_LISTING_SCOPE.REMOVE_USER_ATTRIBUTE_FROM_GROUP
      ? this.attributeId
      : null;
  }

  @Watch('getGroupsStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getGroupsStatusSuccess(): void {
    this.groupStored = this.getUniqGroups([
      ...this.groupStored,
      ...this.groups.items
    ]);
  }

  @Watch('selectedGroupIds')
  public onSelectedGroupUpdate(): void {
    this.$emit('onSelectedGroupUpdate', this.selectedGroups);
  }

  @Watch('groupStored')
  public onGroupStoredUpdate(): void {
    this.$emit('onGroupStoredUpdate', this.groupStored);
  }

  @Watch('initialSelectedGroups')
  public onInitialSelectedGroupsUpdate(): void {
    if (!this.initialSelectedGroups) {
      return;
    }
    const initialSelectedGroupIds = this.initialSelectedGroups?.map(
      (group) => group.id
    );
    this.groupStored = this.initialSelectedGroups;
    this.selectedGroupIds = initialSelectedGroupIds;
    this.groupsToDisable = [...initialSelectedGroupIds];
  }

  @Watch('groupsFromAttribute')
  public onGroupsFromAttributeChange() {
    this.groupStored = this.getUniqGroups([
      ...this.groupStored,
      ...(this.groupsFromAttribute?.items ?? [])
    ]);
  }
}
