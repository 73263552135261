






























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ConfirmDeleteUserAttributeTemplateModal extends Vue {
  @Prop() templateName!: string;

  handleUserConfirmation(decision: boolean): void {
    this.$emit('confirm', decision);
    this.$emit('close');
  }
}
