


























































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { GroupTemplateAttribute } from '@/store/modules/admin/types/group-level-attribute.types';
import { isDefault } from '@/jbi-shared/util/group-level-attributes.util';

import AttributeButtons from '@/views/AdminGroupSettings/components/GroupLevelAttributes/AttributeButtons.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import DraggableIcon from '@/views/AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';
import MultiFieldAttributeOption from './MultiFieldAttributeOption.vue';
import ListAttributeOption from './ListAttributeOption.vue';

@Component({
  components: {
    DraggableIcon,
    AttributeButtons,
    TableActionDropdown,
    MultiFieldAttributeOption,
    ListAttributeOption
  }
})
export default class AttributeWrapper extends Vue {
  @Prop()
  templateAttribute!: GroupTemplateAttribute;
  @Prop() duplicationError!: boolean;
  showField: boolean = false;
  labelErrorMsg: string = '';

  mounted() {
    this.validateLabel();
  }

  /**
   * Validates attribute label content.
   * Displays label field if any error present.
   */
  validateLabel(): void {
    if (this.templateAttribute.label === '') {
      this.labelErrorMsg = 'Label cannot be empty.';
      this.showField = true;
    } else if (this.duplicationError) {
      this.labelErrorMsg = `Duplicated label '${this.templateAttribute.label}'.`;
      this.showField = true;
    } else if (isDefault(this.templateAttribute)) {
      /* Additional checking for default attribute */
      this.labelErrorMsg =
        `'Title' and 'logo'` +
        ' are default attributes and cannot be added as group template attributes.';
      this.showField = true;
    } else {
      this.labelErrorMsg = '';
    }
  }

  hideLabelAndValidate(event: any): void {
    this.validateLabel();
    this.handleLabelUpdate();

    if (event.keyCode === 13 || event.keyCode === 27 || event.type === 'blur') {
      if (this.labelErrorMsg.length === 0) {
        this.showField = false;
      }
    }
  }

  handleAttributeRemove(): void {
    this.$emit('deleteAttribute');
  }

  handleLabelUpdate(): void {
    this.$emit('validateAttribute');
  }

  /**
   * Add a separate watcher for "hasDuplicationError" property
   * because this property is updated by parent.
   */
  @Watch('duplicationError')
  duplicationCallback(currentError: boolean, previousError: boolean): void {
    this.validateLabel();
    this.handleLabelUpdate();
  }
}
