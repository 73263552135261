import { ApiState } from '@/store/types/general.types';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';
import {
  GroupUserAttributeType,
  MyjbiGroup,
  MyjbiGroupDetail,
  MyjbiGroupMember,
  MyjbiGroupStat,
  MyjbiGroupUserAttribute,
  MyjbiGroupUserAttributeSpec
} from '../../../../jbi-shared/types/myjbi-group.types';
import { GetThirdPartyUsersResponsePayload } from '../../../../views/AdminThirdPartyManagement/types/admin-third-party-management.types';
import {
  Application,
  Product
} from '../../application/types/application.types';
import { Profile } from '../../profile/profile.state';
import {
  AddressCountry,
  AddressState,
  GroupLevelAttributeType,
  GroupLevelAttributeValue,
  GroupTemplate
} from './group-level-attribute.types';
import { GetGroupsLicenseResponsePayload } from './group-license.types';
import { GetGroupsProductResponsePayload } from './group-product.types';
import { GroupUserAttributeTemplateUpStream } from './group-user-attribute-template.types';
import {
  GetGroupUserAttributeListResponse,
  GetUploadImageSignedUrlResponse,
  GroupUserAttributeWithGroupCount,
  NewGroupUserAttributeResponse,
  UpdateGroupsUserAttributeResponse
} from './group-user-attribute.types';
import { ProtectedGroupTypes } from './group.types';

export interface AdminApiState {
  inviteNewUserToProduct: ApiState;
  bulkInviteNewUserToProduct: ApiState;
  generateInvitationLinks: ApiState;
  getUsers: ApiState;
  toggleUser: ApiState;
  verifyUser: ApiState;
  removeUserInvitation: ApiState;
  removeInvitation: ApiState;
  getUserInvitations: ApiState;
  resendUserInvitation: ApiState;
  getTermsAndConditions: ApiState;
  getTermsAndConditionsApplication: ApiState;
  getTermsAndConditionsHistory: ApiState;
  addTermsAndConditions: ApiState;
  updateTermsAndConditions: ApiState;
  deleteTermsAndConditions: ApiState;
  setDefaultTermsAndConditions: ApiState;
  getInvitations: ApiState;
  getSubscriptions: ApiState;
  updateSubscriptionStatus: ApiState;
  createNewGroup: ApiState;
  getGroups: ApiState;
  getSpecificGroups: ApiState;
  getSimplifiedGroups: ApiState;
  getGroupsFromAttribute: ApiState;
  getSubGroups: ApiState;
  getMembers: ApiState;
  getGroupAttributeTypes: ApiState;
  getProtectedGroupTypes: ApiState;
  getGroupDetail: ApiState;
  getParentGroupDetailByParentGroupId: ApiState;
  getMasterGroupEmailDomains: ApiState;
  getParentGroupDetailByChildGroupId: ApiState;
  addMembersToGroup: ApiState;
  editGroupMember: ApiState;
  updateGroup: ApiState;
  removeEmailDomain: ApiState;
  deleteGroupMember: ApiState;
  deleteGroup: ApiState;
  getGroupProducts: ApiState;
  createGroupProduct: ApiState;
  updateGroupProduct: ApiState;
  createGroupLicense: ApiState;
  getGroupStats: ApiState;
  getGroupLicenses: ApiState;
  updateGroupLicense: ApiState;
  deleteGroupLicense: ApiState;
  resendEmailNotificationToGroupMember: ApiState;
  exportMembersListToCsv: ApiState;
  exportDeleteMembersListToCsv: ApiState;
  uploadUserListFile: ApiState;
  validateUserList: ApiState;
  validateUserInvite: ApiState;
  validateSingleUserProduct: ApiState;
  getGroupAttributeOrder: ApiState;
  getThirdPartyUsers: ApiState;
  createThirdPartyUser: ApiState;
  getGroupTypes: ApiState;
  groupType: ApiState;
  updateThirdPartyUser: ApiState;
  getMembersCountByGroupId: ApiState;
  getUserByEmail: ApiState;
  updateUserByAdmin: ApiState;
  getUserByAdmin: ApiState;
  getGroupLevelAttributeTypes: ApiState;
  getGroupLevelAttributeValuesByGroupId: ApiState;
  createGroupLevelAttributeValues: ApiState;
  updateGroupLevelAttributeValues: ApiState;
  createGroupTemplate: ApiState;
  getGroupTemplates: ApiState;
  getGroupTemplateById: ApiState;
  updateGroupTemplateApi: ApiState;
  deleteGroupTemplate: ApiState;
  verifyGroupTemplate: ApiState;
  verifyGroupUserAttribute: ApiState;
  createGroupUserAttribute: ApiState;
  updateGroupUserAttribute: ApiState;
  deleteGroupUserAttribute: ApiState;
  updateGroupsWithAttribute: ApiState;
  getSpecsByAttributeId: ApiState;
  getGroupUserAttributeTypes: ApiState;
  getGroupUserAttributeById: ApiState;
  getGroupUserAttributes: ApiState;
  getGroupUserAttributeUploadImageSignedUrl: ApiState;
  getCurrentUserUploadImageSignedUrl: ApiState;
  getCurrentUserAttributes: ApiState;
  updateCurrentUserAttributeValues: ApiState;
  getUserGroupsByUserId: ApiState;
  getGroupUserAttributeList: ApiState;
  getCountries: ApiState;
  getCountryById: ApiState;
  getStatesByCountryId: ApiState;
  getGroupUserAttributeTemplates: ApiState;
  verifyGroupUserAttributeTemplateName: ApiState;
  createGroupUserAttributeTemplate: ApiState;
  deleteGroupUserAttributeTemplate: ApiState;
  updateGroupUserAttributeTemplate: ApiState;
  getFilterUsers: ApiState;
  getFilterGroups: ApiState;
}

export interface Invitation {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  applicationProductId: number;
  token: string;
  createdAt: string;
  expiredAt: string;
  acceptedAt: string | null;
  applicationProduct: Product;
}

export interface User {
  userId: number;
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  type: string;
  status: string;
  username: string;
}

export interface UserWithExtraAttributes extends User {
  [s: string]: any;
}

export interface Pagination<T> {
  itemCount: number;
  items: T[];
  next: string;
  pageCount: number;
  previous: string;
  totalItems: number;
}

export interface AdminState {
  apiState: AdminApiState;
  userInvitations: Invitation[] | undefined;
  userDetails: Pagination<User> | undefined;
  invitations: Invitation[] | undefined;
  subscriptions: any[] | undefined;
  termsAndConditionsPayload: Pagination<TermsAndConditions> | undefined;
  termsAndConditionsApplicationPayload: TermsAndConditions | undefined;
  termsAndConditionsHistoryPayload: TermsAndConditions | undefined;
  groups: GetGroupsResponsePayload | undefined;
  specificGroups: Group[] | undefined;
  simplifiedGroups: Group[] | undefined;
  groupsFromAttribute: GetGroupsResponsePayload | undefined;
  subGroups: GetSubGroupsResponsePayload | undefined;
  protectedGroupTypes: GetProtectedGroupTypesResponsePayload | undefined;
  groupDetail: GroupDetail | undefined;
  members: Pagination<MyjbiGroupMember> | undefined;
  parentGroupDetailByParentGroupId: MyjbiGroupDetail | undefined;
  parentGroupDetailByChildGroupId: MyjbiGroupDetail | undefined;
  groupProducts: GetGroupsProductResponsePayload | undefined;
  groupStats: GetGroupStatsResponsePayload | undefined;
  groupLicenses: GetGroupsLicenseResponsePayload | undefined;
  uploadUserListFile: any | undefined; // change payload to URL returned
  validateUserList: any | undefined; // change payload to URL returned
  validateUserInvite: any | undefined;
  validateSingleUserProduct: InviteUserToProductPayload | undefined;
  groupAttributeOrder: GroupAttributeOrderResponsePayload | undefined;
  groupProductsNameList: string[] | undefined;
  groupList: string[] | undefined;
  masterGroupEmailDomains: string[] | undefined;
  userByEmailResponse: UserByEmailResponsePayload | undefined;
  thirdPartyUsers: GetThirdPartyUsersResponsePayload | undefined;
  groupTypes: Pagination<GroupType> | undefined;
  userProfile: Profile | null;
  groupLevelAttributeTypes: GroupLevelAttributeType[] | undefined;
  groupLevelAttributeValuesByGroupId: GroupLevelAttributeValue[] | undefined;
  groupLevelAttributeValues: GroupLevelAttributeValue[] | undefined;
  groupTemplates: Pagination<GroupTemplate> | undefined;
  groupTemplateById: GroupTemplate | undefined; // used to store created and updated result
  verifyGroupTemplate: boolean | undefined;
  verifyGroupUserAttribute: boolean | undefined;
  groupUserAttributeTypes: GroupUserAttributeType[] | undefined;
  groupUserAttributeById: GroupUserAttributeWithGroupCount | undefined;
  groupUserAttributes: MyjbiGroupUserAttribute[] | undefined;
  newGroupUserAttribute: NewGroupUserAttributeResponse | undefined;
  updatedGroupUserAttribute: UpdateGroupsUserAttributeResponse | undefined;
  groupUserAttributeUploadImageSignedUrl:
    | GetUploadImageSignedUrlResponse
    | undefined;
  currentUserUploadImageSignedUrl: GetUploadImageSignedUrlResponse | undefined;
  groupUserAttributeList: GetGroupUserAttributeListResponse | undefined;
  currentUserAttributes: CurrentUserAttribute[] | undefined;
  getUserGroups: object | undefined;
  attributeSpecs: MyjbiGroupUserAttributeSpec[] | undefined;
  countries: AddressCountry[] | undefined;
  countryById: AddressCountry | undefined;
  statesByCountryId: AddressState[] | undefined;
  groupUserAttributeTemplateExisted: boolean | undefined;
  groupUserAttributeTemplates:
    | Pagination<GroupUserAttributeTemplateUpStream>
    | undefined;
  newGroupUserAttributeTemplateId: number | undefined;
  filteredUsers: User[] | undefined;
  filteredGroups: Group[] | undefined;
}

export interface GroupType {
  id: number;
  name: string;
  status: string;
  createdAt: string;
  updatedDate: string;
  masterGroupCount: number;
  subGroupCount: number;
}

export interface UserProductsInvite {
  productId: number;
  productName: string;
  redeemable: number;
}

export type CheckProductStatus = UserProductsInvite & { productStatus: string };
export interface InviteUserToProductPayload {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  productId: number;
  products?: UserProductsInvite[];
}
export interface DifferenceInviteUserToProductPayload {
  email?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  productId?: number;
  products?: UserProductsInvite[];
}

export interface BulkInviteUserToProductPayload {
  invitations: InviteUserToProductPayload[];
}

export interface GetInvitedUserPayload {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedStatus?: string;
  userType?: string;
  page: number;
  limit: number;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
}
export interface GetFilteredUserListPayload {
  searchInput: string;
  filterBy: string;
  page?: number;
  limit?: number;
}

export interface GetFilteredGroupListPayload {
  groupName: string;
  groupType: string;
  page?: number;
  limit?: number;
}

export type GetInvitedUserPayloadSort = Required<
  Pick<GetInvitedUserPayload, 'sortColumn' | 'sortOrder'>
>;

export interface FilteredUserPayload {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  selectedStatus?: string;
  selectedType?: string;
  perPage?: number;
  page?: number;
}

export interface GetInvitationsPayload {
  email?: string;
  latestActivity?: string;
  applicationId?: string;
  invitationStatus?: string;
  page?: number;
  limit?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
}

export type GetInvitationsPayloadSort = Required<
  Pick<GetInvitationsPayload, 'sortColumn' | 'sortOrder'>
>;

export type InvitationsTabFilterPayload = Required<
  Pick<
    GetInvitationsPayload,
    'email' | 'applicationId' | 'latestActivity' | 'invitationStatus'
  >
>;

export interface UserDetail {
  blocked: boolean;
  createdAt: string;
  createdBy: null;
  email: string;
  emailVerified: boolean;
  id: number;
  passwordHash: string;
  updatedAt: string;
  updatedBy: null;
  userProfile: UserProfile;
  username: string;
}

export interface UserProfile {
  birthdate: null;
  country: null;
  createdAt: string;
  createdBy: null;
  firstName: string;
  gender: null;
  id: number;
  lastName: string;
  organisation: null;
  phoneNumber: null;
  researchgate: null;
  shortbio: null;
  source: null;
  specialty: null;
  updatedAt: string;
  updatedBy: null;
  username: string;
}

export interface TermsAndConditions {
  id: number;
  title: string;
  content: string;
  tncStatus: string;
  createdAt: string;
}
export interface GetTermsAndConditionsPayload extends IPaginationOptions {
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
  application?: string;
  status?: string;
}

export type GetTermsAndConditionsPayloadSort = Required<
  Pick<GetTermsAndConditionsPayload, 'sortColumn' | 'sortOrder'>
>;

export interface AddTermsAndConditionPayload {
  title: number;
  content: string;
  tncStatus: boolean;
  application: string;
}

export interface UpdateTermsAndConditionPayload {
  id: number;
  title: number;
  content: string;
  tncStatus: boolean;
  appId: string;
}

export interface FilteredTermsAndConditionPayload {
  application: string;
  status: string;
}

interface GroupLicenseStatus {
  groupLicenseId: number;
  isLicenseExpired: boolean;
  name: string;
  status: string;
}

export type Group = MyjbiGroup & {
  endAt: Date;
  types: GroupType;
  groupLicenseStatus: GroupLicenseStatus;
};

export type CurrentUserAttribute = MyjbiGroupUserAttributeSpec & {
  groupId: number;
  groupName: string;
  groupPath: string;
};

export type GetGroupsResponsePayload = Pagination<Group>;

export type GetSubGroupsResponsePayload = Pagination<Group>;

export type GetMembersResponsePayload = Pagination<MyjbiGroupMember>;

export type GetGroupStatsResponsePayload = MyjbiGroupStat[];

export type GetProtectedGroupTypesResponsePayload = ProtectedGroupTypes[];

export interface FilteredGroupPayload {
  groupname?: string;
  grouptype?: string;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
  page: number;
  limit?: number;
  attributeId?: number | null;
  scope?: GROUP_LISTING_SCOPE;
}

export interface FilteredSubGroupPayload {
  nlevel: number;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
  page: number;
  limit: number;
}

export type GetGroupFilterPayload = Pick<
  FilteredGroupPayload,
  'groupname' | 'grouptype'
>;

export interface FilteredGetGroup {
  groupname?: string;
  grouptype?: string;
}

export interface FilteredGroupProductsPayload {
  productName?: string;
}

export interface ExportGroupMembersCsvPayload {
  id: number;
  parentsName: string[];
  selectedAttributes?: string[];
}
export interface ExportDeleteGroupMembersCsvPayload {
  groupId: number;
  parentsName: string[];
  emailDomain: string;
  allDomains: string[];
  isFirstDomain: boolean;
}
export interface ValidateUserListPayload {
  fileLink: string;
  emailIndex: number;
  userAttributesSpecs: MyjbiGroupUserAttributeSpec[];
  emailDomains: string[];
}

interface GroupDetailAddOns {
  parentsName: string[];
}

export type GroupDetail = MyjbiGroupDetail & GroupDetailAddOns;

export interface GetGroupProductsPayload {
  productName?: string | undefined;
  page?: number;
  limit?: number;
  sortOrder?: 'ASC' | 'DESC';
  sortColumn?: string;
}

export type GetGroupProductsFilterPayload = Pick<
  GetGroupProductsPayload,
  'productName'
>;

export type GetGroupProductsPayloadSort = Required<
  Pick<GetGroupProductsPayload, 'sortColumn' | 'sortOrder'>
>;

export interface UserTabFilterPayload {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  selectedUserType: string;
  selectedStatus: string;
}

export interface ValidateUserInvited {
  emailIndex: number;
  fileLink: string;
}

export interface GroupAttributeOrder {
  groupId: number;
  attributeOrder: string[];
}
export interface GroupAttributeOrderResponsePayload {
  user: User;
  group: Group;
  id: number;
  groupId: number;
  userId: number;
  attributeOrder: string[];
  createdDate: Date;
  updatedDate: Date;
}
export interface UserByEmailResponsePayload {
  userExist: boolean;
  user?: {
    username: string;
    userId: number;
    firstName: string;
    lastName: string;
    userEmail: string;
  };
}
export interface TermsAndConditionsValues {
  id?: number;
  title: string;
  content: string;
  tncStatus: string | boolean;
  application: string | Application;
  tncParentId?: number;
  isRestoredVersion?: boolean;
  isCurrent?: boolean;
  createdAt?: Date;
}

export enum TermsAndConditionsStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DELETED = 'Deleted'
}
export interface TermsAndConditionEditPayload {
  isTncEdited: boolean;
  title: string;
}

export interface SelectGroupFilterParam {
  selectedType: string;
  filterInput: string;
}

export interface AttributeTypeOption {
  id: number;
  name: string;
  slug: string;
}

/** This enum is added to check scope of GROUPs API to restrict user's groups access  */
export enum GROUP_LISTING_SCOPE {
  ADD_USER_ATTRIBUTE_TO_GROUP = 'update_groups-update_user_attributes-create_existing_user_attributes',
  REMOVE_USER_ATTRIBUTE_FROM_GROUP = 'update_groups-update_user_attributes-delete_added_user_attributes',
  CREATE_NEW_ATTRIBUTE = 'update_groups-update_user_attributes-create_new_user_attributes'
}
