var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[_vm.$style.searchAndCreateContainer]},[_c('b-field',{class:_vm.$style.searchInput},[(
          _vm.isUserAllowed(
            _vm.PermissionsMatrixActionsEnum.READ,
            'group_administration-group_templates-read_group_templates'
          )
        )?_c('b-input',{attrs:{"placeholder":"Search","type":"search","icon":"magnify","icon-clickable":""},on:{"icon-click":_vm.handleSearch,"input":_vm.handleSearch},model:{value:(_vm.searchParams),callback:function ($$v) {_vm.searchParams=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchParams"}}):_vm._e()],1),(_vm.isAllowedToCreateGroupTemplates)?_c('button',{staticClass:"button is-primary",on:{"click":_vm.handleGroupTemplateCreation}},[_vm._v(" Create Group Template ")]):_vm._e()],1),(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-group_templates-read_group_templates'
      )
    )?_c('BasePaginatorHoc',{attrs:{"component":_vm.groupTemplateListComponent,"page":_vm.page,"perPage":_vm.perPage,"isLoading":_vm.isLoading,"sortColumn":_vm.sortColumn,"sortOrder":_vm.sortOrder,"items":_vm.allTemplates,"totalCount":_vm.allTemplates.length},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"sort":_vm.handleSort,"paginate":_vm.handlePaginator,"viewTemplate":_vm.openGroupTemplateView,"editTemplate":_vm.handleGroupTemplateEdit,"deleteTemplate":_vm.handleGroupTemplateDeletion}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }