











































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { GetGroupLicensePayload } from '@/store/modules/admin/types/group-license.types';

@Component({
  components: {}
})
export default class SearchBox extends Vue {
  @Prop(Object) public params!: GetGroupLicensePayload;
  @Prop() public groupProductList!: any;
  @Prop() public groupList!: any;

  @Watch('params', { deep: true })
  @Debounce(500)
  public onChange() {
    this.$emit('input', this.params);
  }

  get StatusesOptions() {
    return [
      { label: 'All Statuses ', value: '' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' }
    ];
  }

  get ProductOptions() {
    const totalResult = [{ id: '-1', label: 'All Products', value: '' }];
    if (this.groupProductList) {
      const result = this.groupProductList.map((res: any, index: number) => ({
        id: `${index}`,
        label: `${res}`,
        value: `${res}`
      }));
      totalResult.push(...result);
    }

    return totalResult;
  }

  get GroupOptions() {
    const totalResult = [{ id: '-1', label: 'All Groups', value: '' }];
    if (this.groupList) {
      const result = this.groupList.map((res: any, index: number) => ({
        id: `${index}`,
        label: `${res}`,
        value: `${res}`
      }));
      totalResult.push(...result);
    }

    return totalResult;
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }

  async handleDatePickerToggle() {
    await this.$nextTick();
    const scroll = () =>
      ((this.$refs?.datepicker as Vue)?.$refs
        ?.dropdown as Vue)?.$el?.scrollIntoView();
    scroll();
  }
}
