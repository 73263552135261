var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"60"}}),_c('SortableTableHeader',{attrs:{"column":"templateName","sortOrder":_vm.getSortOrderOfColumn('templateName'),"showArrow":true,"sort":true,"classes":_vm.$style.increasePaddingLeft},on:{"sort":_vm.onColumnHeaderClick}},[_vm._v(" Group Template Name ")]),_c('th',{attrs:{"column":"attributes"}},[_vm._v("Attribute(s)")])],1)]},proxy:true},(_vm.items)?{key:"body",fn:function(){return [(_vm.items.length > 0)?_vm._l((_vm.items),function(template){return _c('tr',{key:template.id},[_c('td',[_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[(
                  _vm.isUserAllowed(
                    _vm.PermissionsMatrixActionsEnum.UPDATE,
                    'group_administration-group_templates-update_group_templates'
                  )
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.handleGroupTemplateEdit(template.id)}}},[_vm._v(" Edit ")]):_vm._e(),(
                  _vm.isUserAllowed(
                    _vm.PermissionsMatrixActionsEnum.READ,
                    'group_administration-group_templates-read_group_templates'
                  )
                )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.openGroupTemplateView(template.id)}}},[_vm._v(" View Details ")]):_vm._e(),(
                  _vm.isUserAllowed(
                    _vm.PermissionsMatrixActionsEnum.DELETE,
                    'group_administration-group_templates-delete_group_templates'
                  )
                )?_c('b-dropdown-item',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.confirmDeleteTemplate(template)}}},[_vm._v(" Delete ")]):_vm._e()],1)],1),_c('td',{class:_vm.$style.increasePaddingLeft},[_vm._v(" "+_vm._s(template.name)+" ")]),_c('td',[_c('ul',{class:_vm.$style.attributes},[_vm._l((template.groupTemplateAttributes),function(attribute){return _c('li',{key:attribute.id,class:_vm.$style.templateAttributes},[_c('p',{class:_vm.$style.attributeLabel},[(attribute.isRequired)?_c('span',{class:_vm.$style.asterisk},[_c('i',{staticClass:"mdi mdi-asterisk"})]):_vm._e(),_vm._v(" "+_vm._s(attribute.label)+" ")])])}),(_vm.isViewMoreVisible(template))?_c('p',{class:_vm.$style.viewMore},[_vm._v(" "+_vm._s(_vm.getViewMoreText(template))+" ")]):_vm._e()],2)])])}):_vm._e()]},proxy:true}:null,{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v(" "+_vm._s(_vm.getGroupTemplatesApiState.loading ? 'Fetching group templates...' : 'No group template found.')+" ")])],1)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }