

















































import { Component, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import LicenseList from '@/views/AdminGroupManagement/components/LicenseList.vue';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import LicenseFormModal from '@/views/AdminGroupManagement/components/LicenseFormModal.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import {
  CreateGroupLicenseRequestPayload,
  FilteredGroupLicensePayload,
  GetGroupLicenseFilterPayload,
  GetGroupLicensePayload,
  GetGroupLicensePayloadSort,
  GetGroupsLicenseResponsePayload,
  GroupLicense,
  UpdateGroupLicenseRequestPayload
} from '@/store/modules/admin/types/group-license.types';
import SearchBox from './components/SearchBox.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import dayjs from 'dayjs';
import { pluckNewValue } from '@/jbi-shared/util/pluck.rx-operator';
import { filter, pluck } from 'rxjs/operators';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ApiState } from '@/store/types/general.types';
import delay from 'delay';
import { GroupLicenseManualActivationEnum } from '@/enums/group-license-manual-activation.enum';
import { PermissionsMatrixActionsEnum } from '../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../utils/rbac.util';

const initialState = {
  licenseName: '',
  groupProductsName: '',
  groupsName: '',
  status: ''
};

const sortColumnList = () => [
  'createdAt',
  'license',
  'product',
  'group',
  'expiryDate',
  'seats'
];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    LicenseList,
    LicenseFormModal,
    SearchBox
  }
})
export default class LicenseTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'createdAt';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredLicenseParams: FilteredGroupLicensePayload & {
    dates?: any[];
  } = {
    ...initialState
  };
  public isLoading = false;

  @Action('admin/getGroupLicenses')
  getGroupLicenses!: (
    options: Partial<GetGroupLicensePayload>
  ) => Promise<GetGroupsLicenseResponsePayload>;

  @Action('admin/createGroupLicense')
  createGroupLicense!: (
    options: CreateGroupLicenseRequestPayload
  ) => Promise<GroupLicense>;

  @Action('admin/updateGroupLicense')
  updateGroupLicense!: (
    options: UpdateGroupLicenseRequestPayload
  ) => Promise<GroupLicense>;

  @Action('admin/deleteGroupLicense')
  deleteGroupLicense!: (id: number) => Promise<void>;

  @Action('admin/getMembersCountByGroupId')
  getMembersCountByGroupId!: (groupId: number) => Promise<number>;

  @State(({ admin }: RootState) => admin.apiState.getGroupLicenses)
  public getGroupLicensesStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groupLicenses)
  public groupLicenses!: GetGroupsLicenseResponsePayload;

  get isAllowedToCreateLicenses(): boolean {
    return (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-licenses-create_licenses'
      ) &&
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-licenses-read_licenses'
      )
    );
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public async getGroupLicensesList(params: Partial<GetGroupLicensePayload>) {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-licenses-read_licenses'
      )
    ) {
      await this.getGroupLicenses(params);
    }
  }

  get groupProductList() {
    return (this.$store.state as RootState).admin.groupProductsNameList;
  }

  get groupList() {
    return (this.$store.state as RootState).admin.groupList;
  }
  get LicenseList() {
    return LicenseList;
  }
  public async handleSort(data: GetGroupLicensePayloadSort) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      licenseName:
        (this.$route.query.licenseName as string) ||
        this.filteredLicenseParams.licenseName,
      groupProductsName:
        (this.$route.query.groupProductsName as string) ||
        this.filteredLicenseParams.groupProductsName,
      groupsName:
        (this.$route.query.groupsName as string) ||
        this.filteredLicenseParams.groupsName,
      startDate:
        (this.$route.query.startDate as string) ||
        this.filteredLicenseParams.startDate,
      endDate:
        (this.$route.query.endDate as string) ||
        this.filteredLicenseParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredLicenseParams.status,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };
    await this.getGroupLicensesList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'License',
        licenseName:
          (this.$route.query.licenseName as string) ||
          this.filteredLicenseParams.licenseName,
        groupProductsName:
          (this.$route.query.groupProductsName as string) ||
          this.filteredLicenseParams.groupProductsName,
        groupsName:
          (this.$route.query.groupsName as string) ||
          this.filteredLicenseParams.groupsName,
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredLicenseParams.startDate!,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredLicenseParams.endDate!,
        status:
          (this.$route.query.status as string) ||
          this.filteredLicenseParams.status!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    let queryFilter: GetGroupLicenseFilterPayload & { dates?: any[] } = {
      licenseName: this.$route.query.licenseName as string,
      groupProductsName: this.$route.query.groupProductsName as string,
      groupsName: this.$route.query.groupsName as string,
      startDate: this.$route.query.startDate as string,
      endDate: this.$route.query.endDate as string,
      status: this.$route.query.status as string
    };

    if (this.$route.query.startDate && this.$route.query.endDate) {
      queryFilter = {
        ...queryFilter,
        dates: [
          new Date(this.$route.query.startDate as string),
          new Date(this.$route.query.endDate as string)
        ],
        startDate: dayjs(this.$route.query.startDate as string).format(
          'YYYY-MM-DD'
        ),
        endDate: dayjs(this.$route.query.endDate as string).format('YYYY-MM-DD')
      };
    }

    if (
      JSON.stringify(queryFilter) !== JSON.stringify(this.filteredLicenseParams)
    ) {
      this.page = 1;
      await this.getGroupLicensesList({
        licenseName: this.filteredLicenseParams.licenseName,
        groupProductsName: this.filteredLicenseParams.groupProductsName,
        groupsName: this.filteredLicenseParams.groupsName,
        startDate: this.filteredLicenseParams.startDate,
        endDate: this.filteredLicenseParams.endDate,
        status: this.filteredLicenseParams.status,
        limit: +this.$route.query.limit || this.perPage,
        page: 1,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      });

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'License',
          licenseName: this.filteredLicenseParams.licenseName,
          groupProductsName: this.filteredLicenseParams.groupProductsName,
          groupsName: this.filteredLicenseParams.groupsName,
          status: this.filteredLicenseParams.status!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
      if (this.filteredLicenseParams.dates) {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            startDate: this.filteredLicenseParams.startDate as string,
            endDate: this.filteredLicenseParams.endDate as string
          }
        });
      }
    }
  }

  public handleResetFilter() {
    this.filteredLicenseParams = { ...initialState };
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'License') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    const params = {
      licenseName:
        (this.$route.query.licenseName as string) ||
        this.filteredLicenseParams.licenseName,
      groupProductsName:
        (this.$route.query.groupProductsName as string) ||
        this.filteredLicenseParams.groupProductsName,
      groupsName:
        (this.$route.query.groupsName as string) ||
        this.filteredLicenseParams.groupsName,
      startDate:
        (this.$route.query.startDate as string) ||
        this.filteredLicenseParams.startDate,
      endDate:
        (this.$route.query.endDate as string) ||
        this.filteredLicenseParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredLicenseParams.status,
      limit: this.perPage,
      page: this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    };
    await this.getGroupLicensesList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'License',
        licenseName:
          (this.$route.query.licenseName as string) ||
          this.filteredLicenseParams.licenseName,
        groupProductsName:
          (this.$route.query.groupProductsName as string) ||
          this.filteredLicenseParams.groupProductsName,
        groupsName:
          (this.$route.query.groupsName as string) ||
          this.filteredLicenseParams.groupsName,
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredLicenseParams.startDate!,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredLicenseParams.endDate!,
        status:
          (this.$route.query.status as string) ||
          this.filteredLicenseParams.status!,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public handleResetQuery() {
    this.getGroupLicensesList({
      licenseName:
        (this.$route.query.licenseName as string) ||
        this.filteredLicenseParams.licenseName,
      groupProductsName:
        (this.$route.query.groupProductsName as string) ||
        this.filteredLicenseParams.groupProductsName,
      groupsName:
        (this.$route.query.groupsName as string) ||
        this.filteredLicenseParams.groupsName,
      startDate:
        (this.$route.query.startDate &&
          dayjs(this.$route.query.startDate as string).format('YYYY-MM-DD')) ||
        this.filteredLicenseParams.startDate,
      endDate:
        (this.$route.query.endDate &&
          dayjs(this.$route.query.endDate as string).format('YYYY-MM-DD')) ||
        this.filteredLicenseParams.endDate,
      status:
        (this.$route.query.status as string) ||
        this.filteredLicenseParams.status,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: sortColumnList().includes(
        this.$route.query.sortColumn as string
      )
        ? (this.$route.query.sortColumn as string) || this.sortColumn
        : this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
      licenseId:
        +this.$route.query.licenseId || this.filteredLicenseParams.licenseId
    });
  }

  public mounted() {
    if (this.$route.query.tab === 'License') {
      this.$watchAsObservable('filteredLicenseParams', { deep: true })
        .pipe(pluckNewValue(), filter(Boolean), pluck('dates'), filter(Boolean))
        .subscribe((dates: any) => {
          if (dates.length === 0) {
            this.filteredLicenseParams.startDate = '';
            this.filteredLicenseParams.endDate = '';
          } else {
            const [startAt, endAt] = dates as Date[];
            this.filteredLicenseParams.startDate = dayjs(startAt).format(
              'YYYY-MM-DD'
            );
            this.filteredLicenseParams.endDate = dayjs(endAt).format(
              'YYYY-MM-DD'
            );
          }
        });

      this.filteredLicenseParams = {
        licenseName:
          (this.$route.query.licenseName as string) ||
          this.filteredLicenseParams.licenseName,
        groupProductsName:
          (this.$route.query.groupProductsName as string) ||
          this.filteredLicenseParams.groupProductsName,
        groupsName:
          (this.$route.query.groupsName as string) ||
          this.filteredLicenseParams.groupsName,
        startDate:
          (this.$route.query.startDate as string) ||
          this.filteredLicenseParams.startDate,
        endDate:
          (this.$route.query.endDate as string) ||
          this.filteredLicenseParams.endDate,
        status:
          (this.$route.query.status as string) ||
          this.filteredLicenseParams.status
      };
      if (this.$route.query.startDate && this.$route.query.endDate) {
        this.filteredLicenseParams = {
          ...this.filteredLicenseParams,
          dates: [
            new Date(this.$route.query.startDate as string),
            new Date(this.$route.query.endDate as string)
          ]
        };
      }

      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'License',
          licenseName:
            (this.$route.query.licenseName as string) ||
            this.filteredLicenseParams.licenseName,
          groupProductsName:
            (this.$route.query.groupProductsName as string) ||
            this.filteredLicenseParams.groupProductsName,
          groupsName:
            (this.$route.query.groupsName as string) ||
            this.filteredLicenseParams.groupsName,
          startDate:
            (this.$route.query.startDate as string) ||
            this.filteredLicenseParams.startDate!,
          endDate:
            (this.$route.query.endDate as string) ||
            this.filteredLicenseParams.endDate!,
          status:
            (this.$route.query.status as string) ||
            this.filteredLicenseParams.status!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn: sortColumnList().includes(
            this.$route.query.sortColumn as string
          )
            ? (this.$route.query.sortColumn as string) || this.sortColumn
            : this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder,
          licenseId:
            (this.$route.query.licenseId as string) ||
            (this.filteredLicenseParams.licenseId
              ? this.filteredLicenseParams.licenseId.toString()
              : '')
        }
      });

      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
    }
  }

  public async handleTabResetQuery() {
    this.filteredLicenseParams = { ...initialState };
    this.$watchAsObservable('filteredLicenseParams', { deep: true })
      .pipe(pluckNewValue(), filter(Boolean), pluck('dates'), filter(Boolean))
      .subscribe((dates: any) => {
        if (dates.length === 0) {
          this.filteredLicenseParams.startDate = '';
          this.filteredLicenseParams.endDate = '';
        } else {
          const [startAt, endAt] = dates as Date[];
          this.filteredLicenseParams.startDate = dayjs(startAt).format(
            'YYYY-MM-DD'
          );
          this.filteredLicenseParams.endDate = dayjs(endAt).format(
            'YYYY-MM-DD'
          );
        }
      });
    await this.getGroupLicensesList({
      page: this.page,
      limit: this.perPage,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'License') {
      this.handleTabResetQuery();
    }
  }

  @Watch('getGroupLicensesStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getGroupLicensesStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getGroupLicensesStatus.error')
  @isDifferent
  @isTruthy
  public getGroupLicensesStatusError() {
    this.$emit('activateTab');
  }

  openCreateLicenseModal() {
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: LicenseFormModal,
      hasModalCard: true,
      trapFocus: true,
      events: {
        submit: async (v: CreateGroupLicenseRequestPayload) => {
          await this.handleCreateLicense(v);
          close();
        }
      }
    });
  }

  openEditLicenseModal(license: GroupLicense) {
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: LicenseFormModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: license.id,
        name: license.name,
        startAt: license.startAt,
        endAt: license.endAt,
        seats: license.seats,
        groupMemberCount: license.groupMemberCount,
        groupId: license.groupId,
        groupProductId: license.groupProductId,
        isActive: license.isActive
      },
      events: {
        submit: async (v: UpdateGroupLicenseRequestPayload) => {
          await this.handleEditLicense(v);
          close();
        }
      }
    });
  }

  async handleCreateLicense(values: CreateGroupLicenseRequestPayload) {
    try {
      await this.createGroupLicense(values);
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `License ${values.name} successfully created.`,
        duration: 5000
      });
      this.handleResetQuery();
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async handleEditLicense(values: UpdateGroupLicenseRequestPayload) {
    try {
      await this.updateGroupLicense(values);
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `License ${values.name} successfully updated.`,
        duration: 5000
      });
      this.handleResetQuery();
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async confirmActivateLicense(license: GroupLicense) {
    this.isLoading = true;
    const membersCount = await this.getMembersCountByGroupId(license.groupId);
    this.isLoading = false;
    return DialogProgrammatic.confirm({
      message: `<p class="buefy-dialog-title">Activate ${license.name}?</p><p class="buefy-dialog-content">Are you sure you want to activate license <b>${license.name}</b>, group ${license.groupName} has total ${membersCount} members and allocated seats are ${license.seats}?</p>`,
      confirmText: 'Activate',
      type: 'is-primary',
      onConfirm: async () => {
        await this.activateOrDeactivateLicense(
          license,
          true,
          GroupLicenseManualActivationEnum.MANUALLY_ACTIVATED
        );
        ToastProgrammatic.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: `License ${license.name} successfully activated.`,
          duration: 5000
        });
      }
    });
  }

  confirmDeactivateLicense(license: GroupLicense) {
    return DialogProgrammatic.confirm({
      message: `<p class="buefy-dialog-title">Deactivate ${license.name}?</p><p class="buefy-dialog-content">Are you sure you want to deactivate license <b>${license.name}</b>?</p>`,
      confirmText: 'Deactivate',
      type: 'is-primary',
      onConfirm: async () => {
        await this.activateOrDeactivateLicense(
          license,
          false,
          GroupLicenseManualActivationEnum.MANUALLY_DEACTIVATED
        );
        ToastProgrammatic.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: `License ${license.name} successfully deactivated.`,
          duration: 5000
        });
      }
    });
  }

  async activateOrDeactivateLicense(
    { id }: GroupLicense,
    isActive: boolean,
    manualAction: GroupLicenseManualActivationEnum
  ) {
    try {
      await this.updateGroupLicense({ isActive, id, manualAction });
      this.handleResetQuery();
    } catch (error) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async confirmDeleteLicense(license: GroupLicense) {
    if (license.protectedGroup) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: `License ${license.name} is attached to protected group. So, it cannot be deleted.`,
        duration: 5000
      });
    } else {
      const dialogElem: Vue = DialogProgrammatic.confirm({
        message: `<p class="buefy-dialog-title">Delete ${license.name}?</p><p class="buefy-dialog-content">Are you sure you want to delete license <b>${license.name}</b>? This action cannot be undone.</p>`,
        confirmText: 'Cancel',
        onConfirm: () => undefined,
        canCancel: ['button'],
        cancelText: 'Delete',
        type: 'is-primary',
        onCancel: async () => {
          try {
            await this.deleteGroupLicense(license.id);
            ToastProgrammatic.open({
              queue: true,
              type: 'is-dark',
              position: 'is-top',
              message: `License ${license.name} successfully deleted.`,
              duration: 5000
            });
            this.handleResetQuery();
          } catch (error) {
            ToastProgrammatic.open({
              queue: true,
              type: 'is-danger',
              position: 'is-top',
              message: error?.response?.data?.message || error,
              duration: 5000
            });
          }
        }
      });

      while (!dialogElem.$el?.querySelector) {
        await delay(50);
      }
      const cancelBtn = dialogElem.$el?.querySelector?.(
        `.modal-card > footer > button:first-child`
      );
      cancelBtn?.classList?.add?.(`is-red`);
    }
  }
}
