
































































































































import { SortOrder } from '@/jbi-shared/types/search.types';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import BaseLoading from '../../../components/base/BaseLoading.vue';
import BasePagination from '../../../components/base/BasePagination.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BaseTable from '../../../components/BaseTable.vue';
import SortableTableHeader from '../../../components/SortableTableHeader.vue';
import TableActionDropdown from '../../../components/TableActionDropdown.vue';
import { MyjbiGroupUserAttributeList } from '@/jbi-shared/types/myjbi-group.types';
import BaseModal from '../../../jbi-shared/vue-components/BaseModal.vue';
import { RootState } from '@/store/store';
import AddGroupStepper from './update-attribute/AddGroupStepper.vue';
import EditAttributeModal from './EditAttributeModal.vue';
import RemoveGroupModal from './update-attribute/RemoveGroupModal.vue';
import ManageSettingModal from './update-attribute/ManageSettingModal.vue';
import { DialogProgrammatic, ToastProgrammatic } from 'buefy';
import { Vue } from 'vue-property-decorator';
import delay from 'delay';
import { GroupUserAttributeWithGroupCount } from '@/store/modules/admin/types/group-user-attribute.types';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BaseModal,
    BasePagination,
    BaseTable,
    BaseLoading,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class GroupUserAttributeList extends mixins(PaginationMixin) {
  @Prop() public items!: MyjbiGroupUserAttributeList[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public selectedUserType!: string;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: SortOrder;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupUserAttributeList.success
  )
  public getGroupUserAttributeListSuccess!: boolean;

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  groupTypesUserHasAccessTo!: string[];

  @State((state: RootState) => state.rbac.groupsUserHasAccessTo)
  groupsUserHasAccessTo!: number[];

  @Action('admin/deleteGroupUserAttribute')
  deleteGroupUserAttribute!: (attributeId: number) => Promise<void>;

  sortHeaders: Array<{
    placeholder: string;
    sortColumn?: string;
    sort?: boolean;
  }> = [
    { placeholder: 'Attribute Label', sortColumn: 'attributeName', sort: true },
    { placeholder: 'Content Type', sortColumn: 'contentType', sort: true },
    { placeholder: 'Groups', sortColumn: 'groupCount', sort: true }
  ];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public onClickHeaderColumn(columnName: string): void {
    if (this.sortColumn === columnName) {
      this.$emit('sort', {
        sortColumn: columnName,
        sortOrder:
          this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
      });
      return;
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: SortOrder.DESC
    });
  }

  public getSortOrderOfColumn(name: string | undefined): SortOrder {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return SortOrder.ASC;
  }

  public onEditAttribute(attribute: MyjbiGroupUserAttributeList): void {
    this.$buefy.modal.open({
      parent: this,
      component: EditAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: {
        attributeId: attribute.id
      },
      events: {
        attributeUpdateSuccess: (
          updatedAttribute: GroupUserAttributeWithGroupCount
        ): void => {
          this.$emit('updateAttributeList');
        }
      }
    });
  }

  public onAddAttribute(attribute: MyjbiGroupUserAttributeList): void {
    this.$buefy.modal.open({
      parent: this,
      component: AddGroupStepper,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type
      },
      events: {
        updateAttributeList: () => this.$emit('updateAttributeList')
      }
    });
  }

  public onRemoveGroups(attribute: MyjbiGroupUserAttributeList): void {
    this.$buefy.modal.open({
      parent: this,
      component: RemoveGroupModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        id: attribute.id,
        name: attribute.name,
        type: attribute.type
      },
      events: {
        isUpdated: () => this.$emit('updateAttributeList')
      }
    });
  }

  public onManageSettings(attribute: MyjbiGroupUserAttributeList): void {
    this.$buefy.modal.open({
      parent: this,
      component: ManageSettingModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      props: {
        id: attribute.id,
        name: attribute.name
      },
      events: {
        isUpdated: () => this.$emit('updateAttributeList')
      }
    });
  }

  async onDeleteAttribute(
    attribute: MyjbiGroupUserAttributeList
  ): Promise<void> {
    const dialogElem: Vue = DialogProgrammatic.confirm({
      message: `<p class="buefy-dialog-title">Delete Attribute?</p><p class="buefy-dialog-content">
          You are trying to delete <b>${attribute.name}</b>. Deleting this attribute will also delete it from its associated group(s) and it cannot be undone.
        </p>`,
      confirmText: 'Cancel',
      onConfirm: () => undefined,
      canCancel: ['button'],
      cancelText: 'Delete',
      type: 'is-primary',
      onCancel: async () => {
        await this.deleteGroupUserAttribute(attribute.id);
        ToastProgrammatic.open({
          queue: true,
          type: 'is-dark',
          position: 'is-top',
          message: `Attribute ${attribute.name} successfully deleted.`,
          duration: 5000
        });
        this.$emit('updateAttributeList');
      }
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  public checkForPermissions(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypesUserHasAccessTo
      ? this.groupTypesUserHasAccessTo[0]
      : undefined;
    const groupId = this.groupsUserHasAccessTo
      ? this.groupsUserHasAccessTo[0]
      : undefined;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      groupId,
      undefined,
      skipImplicitCheck
    );
  }
}
