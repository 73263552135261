





















import { Component, Vue, Prop } from 'vue-property-decorator';
import { GroupTemplateAttribute as Attribute } from '@/store/modules/admin/types/group-level-attribute.types';

@Component({})
export default class MultiFieldAttributeOption extends Vue {
  @Prop() attribute!: Attribute;
}
