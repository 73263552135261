











































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { JAAS_APP } from '../../../jbi-shared/types/jaas-app.types';
import {
  CreateGroupProductRequestPayload,
  UpdateGroupProductRequestPayload
} from '../../../store/modules/admin/types/group-product.types';
import { RootState } from '../../../store/store';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import { StringInputOption } from '../../../jbi-shared/types/form.types';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class ProductFormModal extends Vue {
  @Prop(String) name!: string;
  @Prop(Number) id!: number;
  @Prop() applicationId!: JAAS_APP;

  public isSubmitButtonDisabled: boolean = true;

  form: Partial<
    CreateGroupProductRequestPayload | UpdateGroupProductRequestPayload
  > = { name: this.name || '', id: this.id, applicationId: this.applicationId };

  get applications(): StringInputOption[] {
    return Object.values(JAAS_APP)
      .filter((v) => v !== JAAS_APP.cplus && v !== JAAS_APP.myjbi)
      .map((v) => ({
        id: v,
        name: v?.toUpperCase()
      }));
  }

  get loading() {
    return (
      (this.$store?.state as RootState).admin.apiState.createGroupProduct
        .loading ||
      (this.$store?.state as RootState).admin.apiState.updateGroupProduct
        .loading
    );
  }

  get editing() {
    return this.id;
  }

  @Watch('form.name')
  @Watch('form.applicationId')
  public watchFormValues() {
    this.isSubmitButtonDisabled = !(this.form.name && this.form.applicationId);
  }

  public mounted() {
    this.watchFormValues();
  }
}
