














































































import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import {
  FilteredGroupPayload,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { GroupType } from '@/store/modules/admin/types/admin.types';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { RootState } from '@/store/store';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class GroupSearch extends Vue {
  @Prop(Object) public filteredGroupParams!: FilteredGroupPayload;

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[] | Pagination<GroupType>;

  @State(({ rbac }: RootState) => rbac.groupTypesUserHasAccessTo)
  private groupTypesUserHasAccessTo!: string[];

  @Watch('filteredGroupParams', { deep: true })
  @Debounce(600)
  public onChange() {
    this.$emit('input', this.filteredGroupParams);
  }

  get isAllowedToCreateGroups(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.CREATE,
      'group_administration-groups-create_groups-create_mastergroup',
      true
    );
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypesUserHasAccessTo
        ? this.groupTypesUserHasAccessTo[0]
        : undefined,
      undefined,
      undefined,
      skipImplicitCheck
    );
  }

  public handleFilter() {
    this.$emit('input', this.filteredGroupParams);
  }

  get typeOptions() {
    if (this.groupTypes) {
      /**
       * Group types is returned as either GroupType[] or wrapped in
       * Pagination. Hence, the need for the awkward check here.
       */
      let dataToMap = [];
      if ('items' in this.groupTypes) {
        dataToMap = this.groupTypes.items;
      } else {
        dataToMap = this.groupTypes;
      }
      const types = dataToMap.map((groupType: GroupType) => ({
        id: groupType.id,
        slug: groupType.name,
        name: groupType.name
      }));
      return [{ id: 0, name: 'All', slug: '' }, ...types];
    }
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
