































import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { FilteredGroupProductsPayload } from '@/store/modules/admin/types/admin.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PermissionsMatrixActionsEnum } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../utils/rbac.util';
@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {}
})
export default class ProductSearch extends Vue {
  @Prop(Object)
  public filteredGroupProductsParams!: FilteredGroupProductsPayload;

  @Watch('filteredGroupProductsParams.productName', { deep: true })
  @Debounce(500)
  public onChange() {
    this.$emit('input', this.filteredGroupProductsParams);
  }

  get isAllowedToCreateProducts(): boolean {
    return (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-products-create_products'
      ) &&
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-products-read_products'
      )
    );
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public handleFilter() {
    this.$emit('input', this.filteredGroupProductsParams);
  }

  public handleCreateProduct() {
    this.$emit('createProduct');
  }
}
