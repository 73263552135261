









































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { isTruthy } from '../../jbi-shared/util/watcher.vue-decorator';
import { Dictionary } from 'vuex';
import {
  debouncer,
  clone
} from '../../jbi-shared/util/group-level-attributes.util';
import {
  ApiState,
  SortOrder,
  PaginatorSpecs
} from '../../store/types/general.types';
import { RootState } from '../../store/store';
import { Pagination } from 'nestjs-typeorm-paginate';
import { ToastProgrammatic as Toast } from 'buefy';

import BasePaginatorHoc from '../../components/base/BasePaginatorHoc.vue';
import UserAttributeTemplateList from './components/UserAttributeTemplate/UserAttributeTemplateList.vue';
import BaseLoading from '../../components/base/BaseLoading.vue';
import EditUserAttributeTemplateModal from './components/UserAttributeTemplate/EditUserAttributeTemplateModal.vue';
import TemplateView from './components/UserAttributeTemplate/TemplateView.vue';
import {
  GetGroupUserAttributeTemplatePayload,
  GroupUserAttributeTemplate,
  GroupUserAttributeTemplateUpStream
} from '../../store/modules/admin/types/group-user-attribute-template.types';
import { MyjbiGroupUserAttribute } from '../../jbi-shared/types/myjbi-group.types';
import { GetGroupUserAttributeListResponse } from '../../store/modules/admin/types/group-user-attribute.types';

/**
 * @description
 * This component lists all user templates and their attributes
 * in a paginated table.
 *
 * FIXME: This component is not optimized.
 *
 * Proper fix will require complete overhaul on:
 * 1. Backend (sql scripts, and data structure)
 * 2. Frontend (update to accomodate said data structure)
 */
@Component({
  components: {
    BasePaginatorHoc,
    UserAttributeTemplateList,
    BaseLoading,
    EditUserAttributeTemplateModal
  }
})
export default class UserAttributesTemplateTab extends Vue {
  page: number = 1;
  perPage: number = 50;
  sortOrder: SortOrder = SortOrder.ASC;
  searchParams: string = '';

  viewTemplateId: number = 0;
  editTemplateId: number = 0;

  isLoading: boolean = false;

  /**
   * TODO: Refactor how template is created.
   *
   * If we house this in the modal instead,
   * we wont have to worry about clearing the template after creation.
   */
  blankTemplate: GroupUserAttributeTemplate = {
    id: 0,
    name: '',
    attributes: []
  };

  clonedTemplate: GroupUserAttributeTemplate = this.blankTemplate;

  @Action('admin/getGroupUserAttributeTemplates')
  getGroupUserAttributeTemplates!: (
    options: GetGroupUserAttributeTemplatePayload
  ) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupUserAttributeTemplates
  )
  getGroupUserAttributeTemplatesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupUserAttributeTemplates)
  groupUserAttributeTemplates!: Pagination<GroupUserAttributeTemplateUpStream>;

  @Action('admin/deleteGroupUserAttributeTemplate')
  deleteGroupUserAttributeTemplate!: (templateId: number) => void;

  @State(
    ({ admin }: RootState) => admin.apiState.deleteGroupUserAttributeTemplate
  )
  deleteGroupUserAttributeTemplateApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupUserAttributeList)
  groupUserAttributeList!: GetGroupUserAttributeListResponse;

  @Action('admin/getGroupUserAttributes')
  getGroupUserAttributes!: () => void;

  @State((state: RootState) => state.admin.groupUserAttributes)
  groupUserAttributes!: MyjbiGroupUserAttribute[];

  mounted() {
    if (this.currentRouteQuery.tab === 'UserAttributesTemplate') {
      const {
        templateName,
        limit,
        page,
        sortOrder,
        viewTemplateId,
        editTemplateId
      } = this.currentRouteQuery;

      this.page = +page || this.page;
      this.perPage = +limit || this.perPage;
      this.sortOrder = this.isSortInvalid(sortOrder as SortOrder)
        ? this.sortOrder
        : (sortOrder as SortOrder);
      this.searchParams = (templateName as string) || this.searchParams;

      this.viewTemplateId = +viewTemplateId || this.viewTemplateId;
      this.editTemplateId = +editTemplateId || this.editTemplateId;

      this.getGroupUserAttributes();
      this.fetchGroupUserAttributeTemplates();
    }
  }

  get currentRouteQuery() {
    return this.$route.query;
  }

  get filterPayload(): GetGroupUserAttributeTemplatePayload {
    return {
      search: this.searchParams,
      limit: this.perPage,
      page: this.page,
      sortOrder: this.sortOrder
    };
  }

  get baseQueryParams(): Dictionary<string | string[]> {
    return {
      tab: 'UserAttributesTemplate',
      templateName: this.searchParams as string,
      limit: this.perPage.toString(),
      page: this.page.toString(),
      sortOrder: this.sortOrder
    } as Dictionary<string>;
  }

  updateParamQuery(option?: { [key: string]: string }): void {
    let queryParams = this.baseQueryParams;

    if (option) {
      queryParams = {
        ...queryParams,
        ...option
      };
    }

    const routerConfig = {
      path: this.$route.path,
      query: queryParams
    };

    this.$router.push(routerConfig);
  }

  get userAttributeTemplateListComponent() {
    return UserAttributeTemplateList;
  }

  get allTemplates(): GroupUserAttributeTemplateUpStream[] {
    return this.groupUserAttributeTemplates
      ? this.groupUserAttributeTemplates?.items
      : [];
  }

  isSortInvalid(sortOrder: SortOrder): boolean {
    return !(sortOrder === SortOrder.ASC || sortOrder === SortOrder.DESC);
  }

  fetchGroupUserAttributeTemplates() {
    const fetchQueryOption: GetGroupUserAttributeTemplatePayload = {
      ...this.filterPayload
    };

    debouncer(700, this.getGroupUserAttributeTemplates, fetchQueryOption);
  }

  /**
   * Maps attributes to their types and options
   */
  mapAttributeTypeAndOption(
    templateId: number
  ): GroupUserAttributeTemplate | undefined {
    /**
     * Exit function when templates or attributes are undefined.
     *
     * Note:
     *
     * It has to wait completion of TWO API calls.
     * 1. Get attributes
     * 2. Get group templates
     */
    if (
      this.groupUserAttributeTemplates === undefined ||
      this.groupUserAttributes === undefined
    ) {
      return;
    }

    const template = this.groupUserAttributeTemplates.items.find(
      (groupTemplate) => groupTemplate.id === templateId
    );

    if (!template) {
      return;
    }

    const templateAttrs = template.attributes.map((attr) => {
      // casting currentAttr explicitly because there will always be attribute type
      const currentAttr = this.groupUserAttributes.find(
        (gatr) => attr.id === gatr.id
      ) as MyjbiGroupUserAttribute;

      // Maps type and option to attribute
      return {
        ...attr,
        groupUserAttributeType: currentAttr?.groupUserAttributeType,
        option: currentAttr?.option
      };
    });
    return {
      id: template.id,
      name: template.name,
      attributes: templateAttrs
    };
  }

  handleSort(sortOrder: SortOrder) {
    this.sortOrder = sortOrder;
    this.fetchGroupUserAttributeTemplates();
  }

  handlePaginator(paginator: PaginatorSpecs) {
    this.perPage = paginator.perPage;
    this.page = paginator.page;

    this.fetchGroupUserAttributeTemplates();
  }

  handleSearch(): void {
    this.fetchGroupUserAttributeTemplates();
  }

  handleGroupUserAttributeTemplateDeletion(templateId: number): void {
    this.deleteGroupUserAttributeTemplate(templateId);
  }

  handleGroupUserAttributeTemplateCreation(): void {
    this.$buefy.modal.open({
      component: EditUserAttributeTemplateModal,
      parent: this,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      fullScreen: true,
      props: {
        template: this.blankTemplate,
        isUpdate: false
      },
      events: {
        createSuccess: (newTemplateId: number) => {
          this.viewTemplateId = newTemplateId;
          this.getGroupUserAttributes();
          this.fetchGroupUserAttributeTemplates();
        },
        updateAttributeList: () => {
          this.getGroupUserAttributes();
          this.fetchGroupUserAttributeTemplates();
        },
        close: () => {
          this.resetLocalTemplates();
        }
      }
    });
  }

  handleGroupUserAttributeTemplateEdit(templateId: number): void {
    const template = this.mapAttributeTypeAndOption(templateId);

    if (template === undefined) {
      return;
    }

    this.clonedTemplate = clone(template);
    this.editTemplateId = templateId;
    this.updateParamQuery({ editTemplateId: this.editTemplateId.toString() });

    this.$buefy.modal.open({
      component: EditUserAttributeTemplateModal,
      parent: this,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      canCancel: false,
      props: {
        template: this.clonedTemplate,
        isUpdate: true
      },
      events: {
        updateSuccess: (updatedTemplateId: number) => {
          this.viewTemplateId = updatedTemplateId;
          this.fetchGroupUserAttributeTemplates();
        },
        updateAttributeList: () => {
          this.getGroupUserAttributes();
          this.fetchGroupUserAttributeTemplates();
        },
        close: () => {
          this.editTemplateId = 0;
          this.updateParamQuery();
          this.resetLocalTemplates();
        }
      }
    });
  }

  openGroupUserAttributeTemplateView(templateId: number): void {
    const template = this.mapAttributeTypeAndOption(templateId);

    this.viewTemplateId = templateId;
    this.updateParamQuery({ viewTemplateId: this.viewTemplateId.toString() });

    this.$buefy.modal.open({
      parent: this,
      component: TemplateView,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: { template },
      events: {
        close: () => {
          this.viewTemplateId = 0;
          this.updateParamQuery();
        }
      },
      onCancel: () => {
        this.viewTemplateId = 0;
        this.updateParamQuery();
      }
    });
  }

  handleUpdateAttributeList() {
    this.getGroupUserAttributes();
  }

  resetLocalTemplates(): void {
    this.blankTemplate.id = 0;
    this.blankTemplate.name = '';
    this.blankTemplate.attributes = [];

    this.clonedTemplate = this.blankTemplate;
  }

  @Watch('currentRouteQuery.tab')
  routeWatcherCallback(tabName: string): void {
    if (tabName === 'UserAttributesTemplate') {
      this.getGroupUserAttributes();
      this.fetchGroupUserAttributeTemplates();
    }
  }

  @isTruthy
  @Watch('getGroupUserAttributeTemplatesApiState', { deep: true })
  getGroupUserAttributeTemplateSuccessCallback(state: ApiState): void {
    if (state.success) {
      this.$emit('activateTab');
      this.isLoading = false;
      this.updateParamQuery();

      if (this.editTemplateId) {
        this.updateParamQuery({
          editTemplateId: this.editTemplateId.toString()
        });

        this.handleGroupUserAttributeTemplateEdit(this.editTemplateId);
        return;
      }

      if (this.viewTemplateId) {
        this.updateParamQuery({
          viewTemplateId: this.viewTemplateId.toString()
        });

        this.openGroupUserAttributeTemplateView(this.viewTemplateId);
      }
    }

    if (state.error) {
      Toast.open('Error getting group templates. Please try again.');
    }
  }

  @isTruthy
  @Watch('deleteGroupUserAttributeTemplateApiState.success')
  deleteGroupUserAttributeTemplateSuccessCallback(): void {
    Toast.open('User attribute template deleted successfully.');
    this.fetchGroupUserAttributeTemplates();
  }

  @isTruthy
  @Watch('getGroupUserAttributeTemplatesApiState.loading')
  @Watch('deleteGroupUserAttributeTemplateApiState.loading')
  toggleLoaderOnApiStateLoading(): void {
    this.isLoading = true;
  }
}
