






























































































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import BasePaginator from '@/components/base/BasePagination.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import ConfirmDeleteGroupTemplateModal from './ConfirmDeleteGroupTemplateModal.vue';

import {
  GroupTemplate,
  GroupTemplateSortSpecs,
  TemplateAttributeDisplayCount
} from '@/store/modules/admin/types/group-level-attribute.types';
import { SortOrder, ApiState } from '@/store/types/general.types';
import { RootState } from '@/store/store';
import { PermissionsMatrixActionsEnum } from '../../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../../utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BaseTable,
    BaseLoading,
    BasePaginator,
    BasePagination,
    SortableTableHeader,
    TableActionDropdown
  }
})
export default class GroupTemplateList extends mixins(PaginationMixin) {
  @Prop() items!: GroupTemplate[];
  @Prop() isLoading!: boolean;
  @Prop() totalNumberOfPage!: number;
  @Prop() totalCount!: number;
  @Prop() isFirstPage!: boolean;
  @Prop() isLastPage!: boolean;
  @Prop() startItemIndex!: number;
  @Prop() endItemIndex!: number;
  @Prop() sortOrder!: SortOrder;
  @Prop() sortColumn!: string;

  @State(({ admin }: RootState) => admin.apiState.getGroupTemplates)
  getGroupTemplatesApiState!: ApiState;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  get attributeDisplayCount(): number {
    return TemplateAttributeDisplayCount;
  }

  isViewMoreVisible(template: GroupTemplate): boolean {
    return template.groupTemplateAttributes.length > this.attributeDisplayCount;
  }

  getViewMoreText(template: GroupTemplate): string {
    return `+${
      template.groupTemplateAttributes.length - this.attributeDisplayCount
    } more`;
  }

  getSortOrderOfColumn(name: string): string {
    return this.sortColumn === name ? this.sortOrder : SortOrder.ASC;
  }

  onColumnHeaderClick(columnName: string): void {
    const sortSpecs: GroupTemplateSortSpecs = {
      sortColumn: columnName,
      sortOrder: SortOrder.DESC
    };

    /* invert sort order if the same column is clicked again */
    if (this.sortColumn === columnName) {
      sortSpecs.sortOrder =
        this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }

    this.$emit('sort', sortSpecs);
  }

  confirmDeleteTemplate(template: GroupTemplate): void {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteGroupTemplateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        templateName: template.name
      },
      events: {
        confirm: (userConfirm: boolean) => {
          if (userConfirm) {
            this.$emit('deleteTemplate', template.id);
          }
        }
      }
    });
  }

  openGroupTemplateView(templateId: number): void {
    this.$emit('viewTemplate', templateId);
  }

  handleGroupTemplateEdit(templateId: number): void {
    this.$emit('editTemplate', templateId);
  }
}
