




























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import { ApiState } from '../../../../store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import draggable from 'vuedraggable';

import {
  GroupLevelAttributeType,
  GroupTemplate,
  GroupTemplateAttribute,
  GroupTemplateAttributeStatus,
  GroupTemplatePayload
} from '@/store/modules/admin/types/group-level-attribute.types';
import {
  clone,
  debouncer,
  isDefault
} from '@/jbi-shared/util/group-level-attributes.util';

import AttributeWrapper from './AttributeWrapper.vue';
import DraggableIcon from '@/views/AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';
import AddGroupLevelAttributeModal from '@/views/AdminGroupSettings/components/GroupLevelAttributes/AddGroupLevelAttributeModal.vue';

@Component({ components: { draggable, AttributeWrapper, DraggableIcon } })
export default class EditGroupTemplateModal extends Vue {
  @Prop() template!: GroupTemplate;
  @Prop() isUpdate!: boolean;

  isSubmitLoading: boolean = false;
  isTemplateNameVerifying: boolean = false;

  deletedAttributes: GroupTemplateAttribute[] = [];

  currentTemplateName: string = '';
  clonedGroupAttributeTypes: GroupLevelAttributeType[] = [];
  templateNameError: boolean = this.template.name === '';
  templateNameErrorMsg: string = '';
  templateNameMsgType: string = '';

  /**
   * Actions and States: Group level attribute types
   */
  @Action('admin/getGroupLevelAttributeTypes')
  getGroupLevelAttributeTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupLevelAttributeTypes)
  getGroupLevelAttributeTypesState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeTypes)
  groupAttributeTypes!: GroupLevelAttributeType[] | undefined;

  /**
   * Actions and States: Group template
   */
  @Action('admin/verifyGroupTemplate')
  verifyGroupTemplateName!: (templateName: string) => any;

  @State(({ admin }: RootState) => admin.apiState.verifyGroupTemplate)
  verifyGroupTemplateApiState!: ApiState;

  @State(({ admin }: RootState) => admin.verifyGroupTemplate)
  isTemplateWithSameNameExisted!: boolean;

  @Action('admin/createGroupTemplate')
  createGroupTemplate!: (payload: GroupTemplatePayload) => void;

  @State(({ admin }: RootState) => admin.apiState.createGroupTemplate)
  createGroupTemplateApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupTemplateById)
  groupTemplateById!: GroupTemplate;

  @Action('admin/updateGroupTemplate')
  updateGroupTemplate!: (payload: GroupTemplatePayload) => void;

  @State(({ admin }: RootState) => admin.apiState.updateGroupTemplateApi)
  updateGroupTemplateApiState!: ApiState;

  /**
   * A few things need to be done initially:
   *
   * 1. Clone all prop values to local.
   * 2. Fetch attribute types onload.
   */

  created() {
    this.currentTemplateName = this.template.name;
    this.template.groupTemplateAttributes.forEach((attribute) => {
      attribute.hasDuplicationError = false;
    });
  }

  mounted() {
    this.clearNameFieldAttr(); // manually clear previous verify results
    this.getGroupLevelAttributeTypes();
  }

  hasFormError(): boolean {
    const isTemplateNameEmpty: boolean = this.template.name === '';

    const hasDefaultAttributeError: boolean = this.template.groupTemplateAttributes.some(
      (attribute) => isDefault(attribute)
    );

    const duplicationError: boolean = this.template.groupTemplateAttributes.some(
      (attribute) => {
        return attribute.hasDuplicationError === true;
      }
    );

    const labelError: boolean = this.template.groupTemplateAttributes.some(
      (attribute) => {
        return attribute.label === '';
      }
    );

    const emptyAttributesError: boolean =
      this.template.groupTemplateAttributes.length === 0;

    if (
      !isTemplateNameEmpty &&
      !hasDefaultAttributeError &&
      !duplicationError &&
      !labelError &&
      !emptyAttributesError &&
      !this.templateNameError
    ) {
      return false;
    }

    return (
      isTemplateNameEmpty ||
      hasDefaultAttributeError ||
      duplicationError ||
      labelError ||
      emptyAttributesError ||
      this.templateNameError
    );
  }

  /**
   * check if new template name is the same before calling api
   */
  handleTemplateNameChange(newTemplateName: string): void {
    if (newTemplateName.trim() === '') {
      this.templateNameError = true;
      this.templateNameErrorMsg = 'Template name cannot be empty.';
      this.templateNameMsgType = 'is-danger';
      return;
    }

    this.clearNameFieldAttr();

    if (newTemplateName !== this.currentTemplateName) {
      this.isTemplateNameVerifying = true;
      debouncer(800, this.verifyGroupTemplateName, this.template.name);
    }
  }

  clearNameFieldAttr(): void {
    this.templateNameError = false;
    this.templateNameErrorMsg = '';
    this.templateNameMsgType = '';
  }

  handleAttributeDrag(): void {
    this.updateAttributeOrdering();
  }

  updateAttributeOrdering(): void {
    this.template.groupTemplateAttributes.forEach((attribute, index) => {
      attribute.order = index + 1;
    });
  }

  closeModal(): void {
    this.$emit('close');
  }

  handleSubmit(): void {
    this.isSubmitLoading = true;

    if (this.isUpdate) {
      this.updateGroupTemplate({
        id: this.template.id,
        templateName: this.template.name,
        attributes: {
          active: this.sanitizeAttributesAsPayload(
            this.template.groupTemplateAttributes
          ),
          deleted: this.sanitizeAttributesAsPayload(this.deletedAttributes)
        }
      });
      return;
    }

    this.createGroupTemplate({
      templateName: this.template.name,
      attributes: {
        active: this.sanitizeAttributesAsPayload(
          this.template.groupTemplateAttributes
        )
      }
    });
  }

  /**
   * Algorithm carried over from GroupLevelAttribute.vue file.
   * Go to file to understand more.
   */
  validateLabelAndTypeDuplication() {
    const dataMap = new Map();
    const validatedAttributes: GroupTemplateAttribute[] = [];
    const allAttributes: GroupTemplateAttribute[] = clone(
      this.template.groupTemplateAttributes
    );

    allAttributes.forEach((attribute, index) => {
      const uniqueKey: string =
        attribute.groupLevelAttributeType.type +
        attribute.label.trim().toLowerCase();

      if (dataMap.has(uniqueKey)) {
        dataMap.get(uniqueKey).hasDuplicationError = true;
        attribute.hasDuplicationError = true;
      } else {
        dataMap.set(uniqueKey, attribute);
        attribute.hasDuplicationError = false;
      }

      validatedAttributes[index] = attribute;
    });

    this.template.groupTemplateAttributes = validatedAttributes;
  }

  handleAttributeUpdate(): void {
    this.validateLabelAndTypeDuplication();
  }

  handleAttributeDelete(attributeIndex: number): void {
    const attributeToDelete: GroupTemplateAttribute = this.template
      .groupTemplateAttributes[attributeIndex];
    this.assignDeleteValue(attributeToDelete);
    this.deletedAttributes.push(attributeToDelete);
    this.template.groupTemplateAttributes.splice(attributeIndex, 1);
    this.validateLabelAndTypeDuplication();
  }

  assignDeleteValue(attribute: GroupTemplateAttribute): void {
    attribute.order = 0;
    attribute.status = GroupTemplateAttributeStatus.DELETE;
    attribute.hasDuplicationError = false;
  }

  handleAddNewAttribute(): void {
    this.$buefy.modal.open({
      parent: this,
      component: AddGroupLevelAttributeModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        attributes: this.template.groupTemplateAttributes,
        allAttributeTypes: this.clonedGroupAttributeTypes,
        isTemplateAttribute: true
      },
      events: {
        addNewAttribute: (newAttribute: GroupTemplateAttribute) => {
          this.template.groupTemplateAttributes.push(newAttribute);
          this.updateAttributeOrdering();

          Toast.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `New attribute added`
          });
        }
      }
    });
  }

  /**
   * Method to extract attribute properties that are
   * only necessary for db operation.
   * So the properties that are used only for rendering
   * don't get passed as part of payload.
   */
  sanitizeAttributesAsPayload(
    attributes: GroupTemplateAttribute[]
  ): GroupTemplateAttribute[] {
    return attributes.map((attribute) => {
      return {
        id: attribute.id,
        order: attribute.order,
        label: attribute.label,
        isRequired: attribute.isRequired,
        groupLevelAttributeType: attribute.groupLevelAttributeType,
        status: attribute.status,
        option: attribute.option,
        createdDate: attribute.createdDate,
        updatedDate: attribute.updatedDate,
        deletedDate: attribute.deletedDate
      };
    });
  }

  @Watch('verifyGroupTemplateApiState', { deep: true, immediate: true })
  verifyTemplateNameApiStateCallback(verifyState: ApiState): void {
    this.isTemplateNameVerifying = false;

    if (verifyState.success) {
      if (this.template.name === '') {
        return;
      }

      if (this.isTemplateWithSameNameExisted) {
        this.templateNameError = true;
        this.templateNameErrorMsg = 'Template name already existed.';
        this.templateNameMsgType = 'is-danger';
        return;
      }

      this.templateNameError = false;
      this.templateNameErrorMsg = 'Template name is available.';
      this.templateNameMsgType = 'is-success';
    }

    if (verifyState.error) {
      this.templateNameError = true;
      this.templateNameErrorMsg = verifyState.error.message;
      this.templateNameMsgType = 'is-danger';
    }
  }

  @Watch('getGroupLevelAttributeTypesState', { deep: true })
  getAttributeTypeApiStateCallback(getTypeApiState: ApiState): void {
    if (getTypeApiState.success) {
      this.clonedGroupAttributeTypes = clone(
        this.groupAttributeTypes
      ) as GroupLevelAttributeType[];
    }

    if (getTypeApiState.error) {
      Toast.open('Error getting group level attributes.');
    }
  }

  @Watch('updateGroupTemplateApiState', { deep: true })
  templateUpdateApiState(apiState: ApiState) {
    this.isSubmitLoading = false;

    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template updated successfully.`
      });

      this.$emit('updateSuccess', this.groupTemplateById.id);
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template update. Please try again.`);
    }
  }

  @Watch('createGroupTemplateApiState', { deep: true })
  templateCreateApiState(apiState: ApiState) {
    this.isSubmitLoading = false;

    if (apiState.success) {
      Toast.open({
        type: 'is-success',
        message: `Template created successfully.`
      });

      this.$emit('createSuccess', this.groupTemplateById.id);
      this.closeModal();
    }

    if (apiState.error) {
      Toast.open(`Error in template creation. Please try again.`);
    }
  }
}
