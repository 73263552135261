

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  GroupTemplateAttribute as Attribute,
  GroupLevelAttributeListType
} from '@/store/modules/admin/types/group-level-attribute.types';

@Component({})
export default class ListAttributeOption extends Vue {
  @Prop() attribute!: Attribute;
  listItemContent: string = '';
  labelErrorMsg: string = '';
  fieldErrorMsg: string = '';

  listConfig: GroupLevelAttributeListType = this.attribute
    .option as GroupLevelAttributeListType;

  get newItemExists(): boolean {
    return this.listConfig.options
      .map((option) => option.trim().toLowerCase())
      .includes(this.listItemContent.trim().toLowerCase());
  }

  addItemToList() {
    if (this.listItemContent === '' || this.newItemExists) {
      return;
    }

    this.listConfig.options.push(this.listItemContent);
    this.listItemContent = '';
  }

  removeListItem(event: any, identifierClass: string, itemIndex: number) {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.listConfig.options.splice(itemIndex, 1);
    }
  }

  @Watch('listItemContent')
  onListItemContentChange(): void {
    if (this.newItemExists) {
      this.fieldErrorMsg = `List item '${this.listItemContent}' already exists.`;
    } else {
      this.fieldErrorMsg = '';
    }
  }

  @Watch('listConfig.isSingleSelection')
  selectionTypeChangeCallback(newIsSingleSelection: boolean) {
    this.listConfig.isSingleSelection = newIsSingleSelection;
    this.listConfig.selected = newIsSingleSelection ? '' : [];
  }
}
