
































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Group } from '../../../../store/modules/admin/types/admin.types';
import SelectedGroupModal from './SelectedGroupModal.vue';

@Component({
  components: {
    SelectedGroupModal
  }
})
export default class SelectedGroupField extends Vue {
  @Prop(Array) public selectedGroups!: Group[];
  @Prop(Array) public groupStored!: Group[];
  @Prop(Array) public groupsToDisable!: number[];

  public openSelectedGroupModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: SelectedGroupModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: {
        selectedGroups: this.selectedGroups,
        groupStored: this.groupStored,
        groupsToDisable: this.groupsToDisable
      },
      events: {
        updateSelectedGroup: (selectedGroupIds: number[]) =>
          this.$emit('updateSelectedGroup', selectedGroupIds)
      }
    });
  }
}
