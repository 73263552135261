






































































import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import GroupRow from './GroupRow.vue';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { Pagination } from 'nestjs-typeorm-paginate';
import { GroupType } from '../../../store/modules/admin/types/admin.types';
import { PermissionsMatrixActionsEnum } from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    GroupRow,
    TableActionDropdown,
    SortableTableHeader
  }
})
export default class GroupTypeList extends mixins(PaginationMixin) {
  sortHeaders = [
    { placeholder: 'Group Type', sortColumn: 'groupType', sort: true },
    { placeholder: 'Master Groups', sortColumn: 'masterGroups', sort: false },
    { placeholder: 'Sub Groups', sortColumn: 'subGroups', sort: false }
  ];
  @Prop() public items!: Pagination<GroupType>;
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
