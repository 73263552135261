


































import { MyjbiGroupUserAttributeSpec } from '@/jbi-shared/types/myjbi-group.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { Group } from '@/store/modules/admin/types/admin.types';
import { UpdateGroupsWithAttributePayload } from '@/store/modules/admin/types/group-user-attribute.types';
import { RootState } from '@/store/store';
import { ApiState } from '@/store/types/general.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import SelectGroupStep from '../create-attribute/SelectGroupStep.vue';
import { ToastProgrammatic as Toast } from 'buefy';
import { GROUP_LISTING_SCOPE } from '../../../../store/modules/admin/types/admin.types';

@Component({
  components: {
    SelectGroupStep
  }
})
export default class RemoveGroupModal extends Vue {
  public selectedGroups: Group[] = [];
  public groupStored: Group[] = [];
  public scope: GROUP_LISTING_SCOPE =
    GROUP_LISTING_SCOPE.REMOVE_USER_ATTRIBUTE_FROM_GROUP;

  @Prop(Number) public id!: number;
  @Prop(String) public name!: string;
  @Prop(String) public type!: string;

  @Action('admin/updateGroupsWithAttribute')
  public updateGroupsWithAttribute!: (
    payload: UpdateGroupsWithAttributePayload
  ) => void;

  @Action('admin/getSpecsByAttributeId')
  getSpecsByAttributeId!: (attributeId: number) => void;

  @State((state: RootState) => state.admin.apiState.updateGroupsWithAttribute)
  public updateGroupsWithAttributeState!: ApiState;

  @State(({ admin }: RootState) => admin.attributeSpecs)
  public attributeSpecs!: MyjbiGroupUserAttributeSpec[];

  public onSelectedGroupUpdate(selectedGroups: Group[]): void {
    this.selectedGroups = selectedGroups;
  }

  public onGroupStoredUpdate(groupStored: Group[]): void {
    this.groupStored = groupStored;
  }

  public closeModal(isUpdated?: boolean): void {
    if (isUpdated) {
      this.$emit('isUpdated');
    }
    this.$emit('close');
  }

  public removeGroupsConfirmation() {
    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Remove Group(s)?</p><p class="buefy-dialog-content">Are you sure you want to remove ${this.name} from ${this.selectedGroups.length} group(s)?</p>`,
      confirmText: 'Remove',
      onConfirm: () => this.onRemoveGroups(),
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  public onRemoveGroups(): void {
    const groupToRemove: number[] = this.selectedGroups.map(
      (group) => group.id
    );
    let selectedGroupIds: number[] = [];
    let selectedRequiredGroupIds: number[] = [];
    let selectedLockedGroupIds: number[] = [];
    this.attributeSpecs.forEach((spec) => {
      const { id } = spec.group!;
      selectedGroupIds = [
        ...selectedGroupIds,
        ...(!groupToRemove.includes(id) ? [id] : [])
      ];
      selectedRequiredGroupIds = [
        ...selectedRequiredGroupIds,
        ...(!groupToRemove.includes(id) && spec.required ? [id] : [])
      ];
      selectedLockedGroupIds = [
        ...selectedLockedGroupIds,
        ...(!groupToRemove.includes(id) && spec.lockAttribute ? [id] : [])
      ];
    });
    this.updateGroupsWithAttribute({
      attributeId: this.id,
      selectedGroupIds,
      selectedRequiredGroupIds,
      selectedLockedGroupIds
    });
  }

  public mounted() {
    this.getSpecsByAttributeId(this.id);
  }

  @Watch('updateGroupsWithAttributeState.error')
  @isTruthy
  public watchCreateAttributeError(): void {
    Toast.open({
      message: 'Something went wrong, try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('updateGroupsWithAttributeState.success')
  @isTruthy
  public watchUpdateGroupSuccess(): void {
    Toast.open({
      message: `${this.name} has been removed from ${this.selectedGroups.length} groups.`,
      type: 'is-dark',
      position: 'is-top'
    });
    this.closeModal(true);
  }
}
