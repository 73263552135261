var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style.createGroupButtonContainer},[(_vm.isAllowedToCreateGroups)?_c('router-link',{staticClass:"button is-primary",attrs:{"to":{ name: 'admin-create-group' }}},[_vm._v(" Create Master Group ")]):_vm._e()],1),(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups'
      )
    )?_c('div',{staticClass:"columns",class:_vm.$style.groupSearchContainer},[_c('div',{staticClass:"column"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-two-thirds"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"field",attrs:{"label":"Group Name"}},[_c('b-input',{attrs:{"placeholder":"","type":"search"},model:{value:(_vm.filteredGroupParams.groupname),callback:function ($$v) {_vm.$set(_vm.filteredGroupParams, "groupname", $$v)},expression:"filteredGroupParams.groupname"}})],1)],1),(
                  _vm.isUserAllowed(
                    _vm.PermissionsMatrixActionsEnum.READ,
                    'group_administration-group_types-read_group_types'
                  )
                )?_c('div',{staticClass:"column"},[_c('b-field',{staticClass:"field",attrs:{"label":"Group Type"}},[_c('b-select',{attrs:{"placeholder":"type","expanded":""},model:{value:(_vm.filteredGroupParams.grouptype),callback:function ($$v) {_vm.$set(_vm.filteredGroupParams, "grouptype", $$v)},expression:"filteredGroupParams.grouptype"}},_vm._l((_vm.typeOptions),function(option){return _c('option',{key:option.id,domProps:{"value":option.slug}},[_vm._v(" "+_vm._s(option.name)+" ")])}),0)],1)],1):_vm._e()])])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:_vm.$style.resetButtonContainer},[_c('a',{staticClass:"has-text-weight-semibold",attrs:{"href":"#"},on:{"click":_vm.resetFilter}},[_vm._v(" Reset Filter ")])])])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }