






































































import { Action, State } from 'vuex-class';
import { ToastProgrammatic as Toast } from 'buefy';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { RootState } from '../../../../store/store';
import ListAttributeOption from './ListAttributeOption.vue';
import { ApiState } from '../../../../store/types/general.types';
import TableActionDropdown from '../../../../components/TableActionDropdown.vue';
import { GroupUserAttribute } from '../../../../store/modules/admin/types/group-user-attribute-template.types';
import DraggableIcon from '../../../../views/AdminGroupSettings/components/GroupLevelAttributes/DraggableIcon.vue';
import { UpdateGroupsUserAttributePayload } from '../../../../store/modules/admin/types/group-user-attribute.types';
import AttributeButtons from '@/views/AdminGroupSettings/components/GroupLevelAttributes/AttributeButtons.vue';
import MultiFieldAttributeOption from '../../../AdminGroupManagement/components/GroupTemplates/MultiFieldAttributeOption.vue';
import { BaseUserAttributeId } from '@/store/modules/profile/profile.state';

@Component({
  components: {
    DraggableIcon,
    AttributeButtons,
    ListAttributeOption,
    TableActionDropdown,
    MultiFieldAttributeOption
  }
})
export default class AttributeWrapper extends Vue {
  @Prop()
  templateAttribute!: GroupUserAttribute;

  showField: boolean = false;
  labelErrorMsg: string = '';
  newLabel: string = this.templateAttribute.name;
  previousLabel: string = this.templateAttribute.name;

  /**
   * Action and State for user attribute update.
   */
  @Action('admin/updateGroupUserAttribute')
  public updateGroupUserAttribute!: (
    payload: UpdateGroupsUserAttributePayload
  ) => void;

  @State(
    (state: RootState) => state.admin.apiState.updateGroupUserAttribute.success
  )
  public updateGroupUserAttributeSuccessState!: ApiState;

  @State(
    (state: RootState) => state.admin.apiState.updateGroupUserAttribute.error
  )
  public updateGroupUserAttributeErrorState!: ApiState;

  /**
   * Return stringified option values as keys for re-rendering
   */
  get attributeOptionKey(): string {
    if (this.templateAttribute.option === null) {
      return '';
    }

    return this.templateAttribute.option.value.join('');
  }

  isDefault(attribute: GroupUserAttribute): boolean {
    return Object.values(BaseUserAttributeId).includes(attribute.id);
  }

  // toggle "locked" spec of an attribute
  toggleAttributeLock(userAttribute: GroupUserAttribute): void {
    userAttribute.lockAttribute = !userAttribute.lockAttribute;
  }

  // toggle "required" spec of an attribute
  toggleAttributeRequire(userAttribute: GroupUserAttribute): void {
    userAttribute.required = !userAttribute.required;
  }

  // remove the attribute from the list not from the DB
  handleAttributeRemove(): void {
    this.$emit('removeAttribute');
  }

  // remove the attribute from the list not from the DB
  handleAttributeEdit(): void {
    this.$emit('editAttribute', this.templateAttribute);
  }

  // handle update the attribute lable in the DB as well.
  handleLabelUpdate(newLable: string): void {
    this.updateGroupUserAttribute({
      label: newLable,
      groupUserAttribute: {
        id: this.templateAttribute.id
      }
    });
  }

  /**
   * watcher functions
   */
  @Watch('updateGroupUserAttributeSuccessState')
  labelUpdatedSuccessfully() {
    if (this.previousLabel !== this.newLabel) {
      Toast.open('Label updated successfully.');
    }
    this.previousLabel = this.newLabel;
  }

  @Watch('updateGroupUserAttributeErrorState')
  labelUpdateFailed() {
    if (this.previousLabel !== this.newLabel) {
      Toast.open('Error updating the label. Please try again.');
    }
    this.newLabel = this.previousLabel;
  }
}
