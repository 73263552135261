




















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { Dictionary } from 'vuex';
import {
  FilteredGroupTemplatesPayload,
  GroupTemplate,
  GroupTemplateSortSpecs
} from '../../store/modules/admin/types/group-level-attribute.types';
import {
  debouncer,
  clone
} from '@/jbi-shared/util/group-level-attributes.util';
import {
  ApiState,
  SortOrder,
  PaginatorSpecs
} from '@/store/types/general.types';
import { RootState } from '../../store/store';
import { Pagination } from 'nestjs-typeorm-paginate';
import { ToastProgrammatic as Toast } from 'buefy';

// component imports
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import GroupTemplateList from './components/GroupTemplates/GroupTemplateList.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import EditGroupTemplateModal from './components/GroupTemplates/EditGroupTemplateModal.vue';
import TemplateView from '@/views/AdminGroupSettings/components/GroupLevelAttributes/TemplateView.vue';
import { PermissionsMatrixActionsEnum } from '../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../utils/rbac.util';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    GroupTemplateList,
    BaseLoading,
    EditGroupTemplateModal
  }
})
export default class GroupTemplateTab extends Vue {
  /**
   * Base query params and filter options
   */
  page: number = 1;
  perPage: number = 50;
  sortOrder: SortOrder = SortOrder.ASC;
  sortColumn: string = 'templateName';
  searchParams: string = '';

  /**
   * Additional query params used to determine if related modal
   * will be opened on db operation success.
   */
  viewTemplateId: number = 0;
  editTemplateId: number = 0;

  isLoading: boolean = false;
  blankTemplate: GroupTemplate = {
    id: 0,
    name: '',
    groupTemplateAttributes: []
  };

  /**
   * Initiate with empty value
   */
  clonedTemplate: GroupTemplate = this.blankTemplate;

  @Action('admin/getGroupTemplates')
  getGroupTemplates!: (options: FilteredGroupTemplatesPayload) => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupTemplates)
  getGroupTemplatesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupTemplates)
  groupTemplates!: Pagination<GroupTemplate>;

  @Action('admin/deleteGroupTemplate')
  deleteGroupTemplate!: (templateId: number) => void;

  @State(({ admin }: RootState) => admin.apiState.deleteGroupTemplate)
  deleteGroupTemplateApiState!: ApiState;

  get isAllowedToCreateGroupTemplates(): boolean {
    return (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-group_templates-create_group_templates'
      ) &&
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_templates-read_group_templates'
      )
    );
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(action, module);
  }

  mounted() {
    /**
     * Capture query params on load.
     * Perform specified fetch if there is,
     * and fetch all if there isn't.
     */
    if (this.currentRouteQuery.tab === 'GroupTemplates') {
      const {
        templateName,
        limit,
        page,
        sortColumn,
        sortOrder,
        viewTemplateId,
        editTemplateId
      } = this.currentRouteQuery;

      this.page = +page || this.page;
      this.perPage = +limit || this.perPage;
      this.sortColumn = (sortColumn as string) || this.sortColumn;
      this.sortOrder = this.isSortInvalid(sortOrder as SortOrder)
        ? this.sortOrder
        : (sortOrder as SortOrder);
      this.searchParams = (templateName as string) || this.searchParams;

      // assign for UX
      this.viewTemplateId = +viewTemplateId || this.viewTemplateId;
      this.editTemplateId = +editTemplateId || this.editTemplateId;

      this.fetchGroupTemplates();
    }
  }

  /**
   * Returns the CURRENT route query. Type casting is forced only to
   * the selected and expected keys from the query object.
   */
  get currentRouteQuery() {
    return this.$route.query;
  }

  /**
   * Constructs and returns object/option used to fetch templates.
   */
  get filterPayload(): FilteredGroupTemplatesPayload {
    return {
      templateName: this.searchParams,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };
  }

  /**
   * Constructs and returns object that's used to update route query based on the
   * admin's latest interaction with the page, so the route query matches the interaction.
   */
  get baseQueryParams(): Dictionary<string | string[]> {
    return {
      tab: 'GroupTemplates',
      templateName: this.searchParams as string,
      limit: this.perPage.toString(),
      page: this.page.toString(),
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    } as Dictionary<string>;
  }

  /**
   * This method updates existing route query params
   * by combining base query params and additional options.
   *
   * Note:
   * This method only updates the route query params,
   * APIs and other related functions are not invoked.
   */
  updateParamQuery(option?: { [key: string]: string }): void {
    let queryParams = this.baseQueryParams;

    if (option) {
      queryParams = {
        ...queryParams,
        ...option
      };
    }

    const routerConfig = {
      path: this.$route.path,
      query: queryParams
    };

    this.$router.push(routerConfig);
  }

  get groupTemplateListComponent() {
    return GroupTemplateList;
  }

  get allTemplates(): GroupTemplate[] {
    return this.groupTemplates ? this.groupTemplates?.items : [];
  }

  /**
   * To check sortOrder value as a fail safe.
   * Values other than 'ASC' or 'DESC' can cause error.
   */
  isSortInvalid(sortOrder: SortOrder): boolean {
    return !(sortOrder === SortOrder.ASC || sortOrder === SortOrder.DESC);
  }

  /**
   * Unified method to fetch templates with given options for drier code.
   *
   * This method is only FETCHING the templates,
   * it doesn't update the route query.
   *
   * Because getGroupTemplates is synchronous,
   * the route query updating only happens in the callback function on fetch success.
   */
  fetchGroupTemplates() {
    const fetchQueryOption: FilteredGroupTemplatesPayload = {
      ...this.filterPayload
    };
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_templates-read_group_templates'
      )
    ) {
      debouncer(700, this.getGroupTemplates, fetchQueryOption);
    }
  }

  handleSort(sortSpecs: GroupTemplateSortSpecs) {
    this.sortColumn = sortSpecs.sortColumn;
    this.sortOrder = sortSpecs.sortOrder;

    this.fetchGroupTemplates();
  }

  handlePaginator(paginator: PaginatorSpecs) {
    this.perPage = paginator.perPage;
    this.page = paginator.page;

    this.fetchGroupTemplates();
  }

  handleSearch(): void {
    this.fetchGroupTemplates();
  }

  handleGroupTemplateDeletion(templateId: number): void {
    this.deleteGroupTemplate(templateId);
  }

  handleGroupTemplateCreation(): void {
    this.$buefy.modal.open({
      component: EditGroupTemplateModal,
      parent: this,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      fullScreen: true,
      props: {
        template: this.blankTemplate,
        isUpdate: false
      },
      events: {
        createSuccess: (newTemplateId: number) => {
          this.viewTemplateId = newTemplateId;
          this.fetchGroupTemplates();
        },
        close: () => {
          this.resetLocalTemplates();
        }
      }
    });
  }

  handleGroupTemplateEdit(templateId: number): void {
    const template = this.groupTemplates.items.find(
      (groupTemplate) => groupTemplate.id === templateId
    );

    if (!template) {
      return;
    }

    this.clonedTemplate = clone(template);
    this.editTemplateId = templateId;
    this.updateParamQuery({ editTemplateId: this.editTemplateId.toString() });

    this.$buefy.modal.open({
      parent: this,
      component: EditGroupTemplateModal,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: true,
      /**
       * Note:
       * If "canCancel" is changed to true,
       * please add "onCancel" callback handler accordingly.
       */
      canCancel: false,
      props: {
        template: this.clonedTemplate,
        isUpdate: true
      },
      events: {
        updateSuccess: (updatedTemplateId: number) => {
          this.fetchGroupTemplates();
          this.viewTemplateId = updatedTemplateId;
        },

        close: () => {
          this.editTemplateId = 0;
          this.updateParamQuery();
          this.resetLocalTemplates();
        }
      }
    });
  }

  openGroupTemplateView(templateId: number): void {
    const template = this.groupTemplates.items.find(
      (groupTemplate) => groupTemplate.id === templateId
    );

    if (!template) {
      return;
    }

    this.viewTemplateId = templateId;
    this.updateParamQuery({ viewTemplateId: this.viewTemplateId.toString() });

    this.$buefy.modal.open({
      parent: this,
      component: TemplateView,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      props: { template },
      events: {
        close: () => {
          this.viewTemplateId = 0;
          this.updateParamQuery();
        }
      },
      /**
       * Callback when user close modal using "esc" key.
       * Buefy modals can be cancelled by default, and they handle
       * "close" and "cancel" differently.
       */
      onCancel: () => {
        this.viewTemplateId = 0;
        this.updateParamQuery();
      }
    });
  }

  /**
   * Reset values of local templates:
   * 1. blank template (used to ceate new template)
   * 2. cloned template (used to edit an existing template)
   */
  resetLocalTemplates(): void {
    this.blankTemplate.id = 0;
    this.blankTemplate.name = '';
    this.blankTemplate.groupTemplateAttributes = [];

    this.clonedTemplate = this.blankTemplate;
  }

  /* WATCHERS */

  /**
   * Updated group templates should be fetched everytime when this tab is active.
   */
  @Watch('currentRouteQuery.tab')
  routeWatcherCallback(tabName: string): void {
    if (tabName === 'GroupTemplates') {
      this.fetchGroupTemplates();
    }
  }

  /**
   * Fetch success callback to update state and route query.
   */
  @isTruthy
  @Watch('getGroupTemplatesApiState')
  getTemplateSuccessCallback(state: ApiState): void {
    if (state.success) {
      this.$emit('activateTab');
      this.isLoading = false;
      this.updateParamQuery();

      /**
       * These "if" blocks look for "editTemplateId" or "viewTemplateId"
       * in URL query params to open related modal on fetch success.
       *
       * If they both exist, edit modal will take precedence.
       */
      if (this.editTemplateId) {
        this.updateParamQuery({
          editTemplateId: this.editTemplateId.toString()
        });

        this.handleGroupTemplateEdit(this.editTemplateId);
        return;
      }

      if (this.viewTemplateId) {
        this.updateParamQuery({
          viewTemplateId: this.viewTemplateId.toString()
        });

        this.openGroupTemplateView(this.viewTemplateId);
        return;
      }
    }

    if (state.error) {
      Toast.open('Error getting group templates. Please try again.');
    }
  }

  @isTruthy
  @Watch('deleteGroupTemplateApiState.success')
  deleteGroupTemplateApiStateSuccessCallback(): void {
    Toast.open('Group template deleted successfully.');
    this.fetchGroupTemplates();
  }

  /* generic loading callback */
  @isTruthy
  @Watch('getGroupTemplatesApiState.loading')
  @Watch('deleteGroupTemplateApiState.loading')
  toggleLoaderOnApiStateLoading(): void {
    this.isLoading = true;
  }
}
