var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAllowedToCreateLicenses)?_c('div',{class:_vm.$style.createGroupButtonContainer},[_c('button',{staticClass:"button is-primary",on:{"click":_vm.openCreateLicenseModal}},[_vm._v(" Create License ")])]):_vm._e(),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}}),(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-licenses-read_licenses'
      )
    )?_c('SearchBox',{attrs:{"params":_vm.filteredLicenseParams,"groupProductList":_vm.groupProductList,"groupList":_vm.groupList},on:{"resetFilter":function () { return _vm.handleResetFilter(); },"input":_vm.handleFilter}}):_vm._e(),(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-licenses-read_licenses'
      )
    )?_c('BasePaginatorHoc',{attrs:{"component":_vm.LicenseList,"page":_vm.page,"perPage":_vm.perPage,"sortColumn":_vm.sortColumn,"sortOrder":_vm.sortOrder,"items":_vm._get(_vm.groupLicenses, 'items', 0),"totalCount":_vm._get(_vm.groupLicenses, 'totalItems', 0)},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"sort":function (data) { return _vm.handleSort(data); },"paginate":_vm.handlePaginator,"edit":_vm.openEditLicenseModal,"activate":_vm.confirmActivateLicense,"deactivate":_vm.confirmDeactivateLicense,"delete":_vm.confirmDeleteLicense}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }