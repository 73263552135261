var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.$emit('submit', _vm.form);
        });
      })($event)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"720px"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{class:_vm.$style.modalCardTitle},[(_vm.editing)?[_vm._v(" Edit Product ")]:[_vm._v(" Create Product ")]],2),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body"},[(_vm.id)?_c('input',{attrs:{"name":"id","type":"hidden"},domProps:{"value":_vm.id}}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.name),expression:"form.name"}],staticClass:"input",attrs:{"maxlength":"255"},domProps:{"value":(_vm.form.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "name", $event.target.value)}}})])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Application"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Application","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.editing},model:{value:(_vm.form.applicationId),callback:function ($$v) {_vm.$set(_vm.form, "applicationId", $$v)},expression:"form.applicationId"}},_vm._l((_vm.applications),function(application){return _c('option',{key:application.id,domProps:{"value":application.id}},[_vm._v(" "+_vm._s(application.name)+" ")])}),0)],1)]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.loading },attrs:{"type":"submit","disabled":_vm.isSubmitButtonDisabled}},[(_vm.editing)?[_vm._v(" Save ")]:[_vm._v(" Create Product ")]],2)])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }