




























import { Component, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import GroupList from '@/views/AdminGroupManagement/components/GroupList.vue';
import GroupSearch from '@/views/AdminGroupManagement/components/GroupSearch.vue';
import { Action, State } from 'vuex-class';
import { Pagination } from 'nestjs-typeorm-paginate';
import {
  FilteredGetGroup,
  FilteredGroupPayload,
  GetGroupFilterPayload,
  GetGroupsResponsePayload,
  Group
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ApiState } from '@/store/types/general.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

const initialState = {
  groupname: '',
  grouptype: ''
};

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    GroupList,
    GroupSearch
  }
})
export default class GroupTab extends Vue {
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredGroupParams: FilteredGetGroup = { ...initialState };
  public expandGroupFilter = false;

  @Action('admin/getGroups')
  getGroups!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getGroupTypes')
  getGroupTypes!: () => void;

  @State(({ admin }: RootState) => admin.apiState.getGroups)
  public getGroupsStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groups)
  private groups!: Pagination<Group[]>;

  @State(({ rbac }: RootState) => rbac.groupsUserHasAccessTo)
  private groupsUserHasAccessTo!: number[];

  @State(({ rbac }: RootState) => rbac.groupTypesUserHasAccessTo)
  private groupTypesUserHasAccessTo!: string[];

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypesUserHasAccessTo
        ? this.groupTypesUserHasAccessTo[0]
        : undefined,
      this.groupsUserHasAccessTo ? this.groupsUserHasAccessTo[0] : undefined
    );
  }

  public handleResetFilter() {
    this.filteredGroupParams = { ...initialState };
  }

  public async getGroupList(params: FilteredGroupPayload) {
    if (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups'
      )
    ) {
      await this.getGroups(params);
    }
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Groups') {
      return;
    }
    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
    await this.getGroupList({
      groupname:
        (this.$route.query.groupname as string) ||
        this.filteredGroupParams.groupname,
      grouptype:
        (this.$route.query.grouptype as string) ||
        this.filteredGroupParams.grouptype,
      limit: this.perPage,
      page: this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Groups',
        groupname:
          this.$route.query.groupname || this.filteredGroupParams.groupname!,
        grouptype:
          this.$route.query.grouptype || this.filteredGroupParams.grouptype!,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });

    this.expandGroupFilter = false;
    if (
      this.filteredGroupParams.groupname?.length ||
      this.filteredGroupParams.grouptype?.length
    ) {
      this.expandGroupFilter = true;
    }
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      groupname:
        (this.$route.query.groupname as string) ||
        this.filteredGroupParams.groupname,
      grouptype:
        (this.$route.query.grouptype as string) ||
        this.filteredGroupParams.grouptype,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getGroupList(params);

    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Groups',
        groupname:
          this.$route.query.groupname || this.filteredGroupParams.groupname!,
        grouptype:
          this.$route.query.grouptype || this.filteredGroupParams.grouptype!,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: GetGroupFilterPayload = {
      groupname: (this.$route.query.groupname as string) ?? '',
      grouptype: (this.$route.query.grouptype as string) ?? ''
    };

    if (
      JSON.stringify(queryFilter) !== JSON.stringify(this.filteredGroupParams)
    ) {
      this.page = 1;
      this.expandGroupFilter = false;

      setTimeout(async () => {
        await this.getGroupList({
          groupname: this.filteredGroupParams.groupname,
          grouptype: this.filteredGroupParams.grouptype,
          limit: +this.$route.query.limit || this.perPage,
          page: 1,
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        });
        this.$router.push({
          path: this.$route.path,
          query: {
            tab: 'Groups',
            groupname: this.filteredGroupParams.groupname!,
            grouptype: this.filteredGroupParams.grouptype!,
            limit:
              (this.$route.query.limit as string) || this.perPage.toString(),
            page: (this.$route.query.page as string) || this.page.toString(),
            sortColumn:
              (this.$route.query.sortColumn as string) || this.sortColumn,
            sortOrder:
              (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
          }
        });

        this.expandGroupFilter = true;
        if (
          this.filteredGroupParams.groupname?.length === 0 &&
          this.filteredGroupParams.grouptype?.length === 0
        ) {
          this.expandGroupFilter = false;
        }
      }, 800);
    }
  }

  get GroupList() {
    return GroupList;
  }

  public async handleResetQuery() {
    this.getGroupList({
      groupname:
        (this.$route.query.groupname as string) ||
        this.filteredGroupParams.groupname,
      grouptype:
        (this.$route.query.grouptype as string) ||
        this.filteredGroupParams.grouptype,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  public async handleTabResetQuery() {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes();
    }
    this.filteredGroupParams = { ...initialState };

    await this.getGroupList({
      page: this.page,
      limit: this.perPage,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }
  public mounted() {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes();
    }
    if (this.$route.query.tab === 'Groups') {
      if (
        this.filteredGroupParams.groupname?.length ||
        this.filteredGroupParams.grouptype?.length
      ) {
        this.expandGroupFilter = true;
      }
      this.filteredGroupParams = {
        groupname:
          (this.$route.query.groupname as string) ||
          this.filteredGroupParams.groupname,
        grouptype:
          (this.$route.query.grouptype as string) ||
          this.filteredGroupParams.grouptype
      };
      this.$router.push({
        path: this.$route.path,
        query: {
          tab: 'Groups',
          groupname:
            (this.$route.query.groupname as string) ||
            this.filteredGroupParams.groupname!,
          grouptype:
            (this.$route.query.grouptype as string) ||
            this.filteredGroupParams.grouptype!,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });

      this.perPage = +this.$route.query.limit || this.perPage;
      this.page = +this.$route.query.page || this.page;
      this.handleResetQuery();
      this.expandGroupFilter = false;
      if (
        this.filteredGroupParams.groupname?.length ||
        this.filteredGroupParams.grouptype?.length
      ) {
        this.expandGroupFilter = true;
      }
    }
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Groups') {
      this.expandGroupFilter = false;

      this.handleTabResetQuery();
    }
  }

  @Watch('getGroupsStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getGroupsStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getGroupsStatus.error')
  @isDifferent
  @isTruthy
  public getGroupsStatusError() {
    this.$emit('activateTab');
  }
}
