
































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeOption,
  GroupUserAttributeTypeStatus
} from '../../../../jbi-shared/types/myjbi-group.types';
import { isTruthy } from '../../../../jbi-shared/util/watcher.vue-decorator';
import {
  Group,
  GROUP_LISTING_SCOPE
} from '../../../../store/modules/admin/types/admin.types';
import {
  GroupUserAttributePayload,
  NewGroupUserAttributeResponse,
  UpdateGroupsWithAttributePayload
} from '../../../../store/modules/admin/types/group-user-attribute.types';
import { RootState } from '../../../../store/store';
import { ApiState } from '../../../../store/types/general.types';
import DefineFieldStep, { DefineFieldParam } from './DefineFieldStep.vue';
import ManageSettingStep from './ManageSettingStep.vue';
import SelectGroupStep from './SelectGroupStep.vue';
import { ToastProgrammatic as Toast } from 'buefy';

@Component({
  components: {
    DefineFieldStep,
    SelectGroupStep,
    ManageSettingStep
  }
})
export default class CreateAttributeStepper extends Vue {
  public params: DefineFieldParam = {
    contentType: {
      id: 0,
      type: '',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE
    },
    label: '',
    selectedTypeOption: null
  };
  public selectedGroups: Group[] = [];
  public selectedRequireGroups: number[] = [];
  public selectedLockGroups: number[] = [];
  public groupStored: Group[] = [];

  public activeStep: number = 0;
  public isNextStepDisabled: boolean = true;
  public onlyCreateAttribute: boolean = false;
  public scope: GROUP_LISTING_SCOPE = GROUP_LISTING_SCOPE.CREATE_NEW_ATTRIBUTE;

  @Action('admin/createGroupUserAttribute')
  public createGroupUserAttribute!: (
    payload: GroupUserAttributePayload
  ) => void;

  @Action('admin/updateGroupsWithAttribute')
  public updateGroupsWithAttribute!: (
    payload: UpdateGroupsWithAttributePayload
  ) => void;

  @State((state: RootState) => state.admin.apiState.createGroupUserAttribute)
  public createGroupUserAttributeState!: ApiState;

  @State((state: RootState) => state.admin.newGroupUserAttribute)
  public createNewGroupUserAttributeResponse!: NewGroupUserAttributeResponse;

  @State((state: RootState) => state.admin.apiState.updateGroupsWithAttribute)
  public updateGroupsWithAttributeState!: ApiState;

  public updateParams(params: DefineFieldParam) {
    this.params = params;
  }

  public closeModal(isCreatedAttribute?: boolean): void {
    if (isCreatedAttribute) {
      this.$emit('onCreateAttribute');
    }
    this.$emit('close');
  }

  public nextStep(): void {
    this.activeStep += 1;
  }

  public prevStep(): void {
    this.activeStep -= 1;
  }

  public isDisableNext(disable: boolean): void {
    this.isNextStepDisabled = disable;
  }

  public onSelectedGroupUpdate(selectedGroups: Group[]): void {
    this.selectedGroups = selectedGroups;
  }

  public onSelectedRequiredGroupIds(selectedGroupIds: number[]): void {
    this.selectedRequireGroups = selectedGroupIds;
  }

  public onSelectedLockGroupIds(selectedGroupIds: number[]): void {
    this.selectedLockGroups = selectedGroupIds;
  }

  public onGroupStoredUpdate(groupStored: Group[]): void {
    this.groupStored = groupStored;
  }

  public createAttribute(): void {
    this.onlyCreateAttribute = true;
    this.createGroupUserAttribute({
      label: this.params.label,
      status: GroupUserAttributeTypeStatus.ACTIVE,
      option: this.params.selectedTypeOption
        ? ({ ...this.params.selectedTypeOption } as GroupUserAttributeOption)
        : null,
      groupUserAttributeType: this.params.contentType
    });
  }

  public skipSettingsAndCreateAttribute() {
    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Skip and Create?</p><p class="buefy-dialog-content">Are you sure want to skip the next 2 steps and create this attribute? You can always skip and continue later.</p>`,
      confirmText: 'Cancel',
      onConfirm: () => undefined,
      canCancel: ['button'],
      cancelText: 'Skip',
      onCancel: () => this.createAttribute()
    });
  }

  public createAttributeAndUpdateGroups(): void {
    this.createAttribute();
    this.onlyCreateAttribute = false;
  }

  @Watch('createGroupUserAttributeState.success')
  @isTruthy
  public watchCreateAttributeSuccess(): void {
    if (!this.onlyCreateAttribute) {
      this.updateGroupsWithAttribute({
        attributeId: this.createNewGroupUserAttributeResponse
          .group_user_attribute.id,
        selectedGroupIds: this.selectedGroups.map((group) => group.id),
        selectedRequiredGroupIds: this.selectedRequireGroups,
        selectedLockedGroupIds: this.selectedLockGroups
      });
    } else {
      this.closeModal(true);
    }
    Toast.open({
      message: 'New attribute created',
      type: 'is-dark',
      position: 'is-top'
    });
  }

  @Watch('updateGroupsWithAttributeState.error')
  @Watch('createGroupUserAttributeState.error')
  @isTruthy
  public watchCreateAttributeError(): void {
    Toast.open({
      message: 'Something went wrong, try again.',
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('updateGroupsWithAttributeState.success')
  @isTruthy
  public watchUpdateGroupSuccess(): void {
    Toast.open({
      message: `${this.params.label} added to ${this.selectedGroups.length} new group(s).`,
      type: 'is-dark',
      position: 'is-top'
    });
    this.closeModal(true);
  }
}
