






























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep as _cloneDeep } from 'lodash';
import { GroupType } from '../../../store/modules/admin/types/admin.types';

@Component({ components: {} })
export default class GroupTypeModal extends Vue {
  @Prop()
  public modalTitle!: string;
  @Prop()
  public groupTypeItem!: GroupType | null;

  public groupTypeName: string = '';
  public isNameInvalid: boolean = false;
  public isSubmitBtnDisabled: boolean = true;

  public mounted() {
    if (this.groupTypeItem) {
      this.groupTypeName = this.groupTypeItem.name;
    }
  }

  public confirm() {
    this.groupTypeName = this.groupTypeName.trim();
    if (this.groupTypeName === '') {
      this.isNameInvalid = true;
      this.isSubmitBtnDisabled = true;
      return;
    }
    if (this.groupTypeItem) {
      this.$emit('confirmEdit', {
        ...this.groupTypeItem,
        name: this.groupTypeName
      });
      this.$emit('close');
    } else {
      this.$emit('confirmCreate', this.groupTypeName);
      this.$emit('close');
    }
  }

  public closeModal() {
    this.$emit('close');
  }

  @Watch('groupTypeName')
  public watchGroupTypeName(newValue: string) {
    if (newValue === '') {
      this.isNameInvalid = true;
      this.isSubmitBtnDisabled = true;
    } else {
      this.isNameInvalid = false;
      this.isSubmitBtnDisabled = false;
    }
  }
}
