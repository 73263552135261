














































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeType,
  GroupUserAttributeOption
} from '../../../jbi-shared/types/myjbi-group.types';
import {
  GroupUserAttributeWithGroupCount,
  UpdateGroupsUserAttributePayload
} from '../../../store/modules/admin/types/group-user-attribute.types';
import { RootState } from '../../../store/store';
import { ApiState } from '../../../store/types/general.types';
import { ToastProgrammatic as Toast } from 'buefy';
import ListAttributeOption from '@/views/AdminGroupManagement/components/GroupTemplates/ListAttributeOption.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import {
  clone,
  debouncer
} from '@/jbi-shared/util/group-level-attributes.util';

interface ErrorTypeAndMessage {
  type: string;
  message: string;
}

/**
 * NOTE:
 *
 * This modal is used to edit attributes in 2 tabs under User Admin page:
 * 1. Attributes tab
 * 2. User attribute templates tab
 *
 * This modal only support updates for:
 * 1. attribute label name
 * 2. attribute option (list type attributes only)
 */
@Component({
  components: {
    ListAttributeOption,
    BaseLoading
  }
})
export default class EditAttributeModal extends Vue {
  @Prop(Number) public attributeId!: number;

  public name: string = '';
  public type: string = '';
  public groupCount: number = 0;
  public contentType: GroupUserAttributeType | null = null;

  public option: GroupUserAttributeOption | null = null;
  public updatedName: string = '';
  public isDuplicateAttributeFound: boolean = false;

  @Action('admin/getGroupUserAttributeById')
  public getGroupAttributeWithGroupCount!: (attributeId: number) => void;

  @Action('admin/verifyGroupUserAttribute')
  public verifyGroupUserAttribute!: (attributeLabel: string) => void;

  @Action('admin/updateGroupUserAttribute')
  public updateGroupUserAttribute!: (
    payload: UpdateGroupsUserAttributePayload
  ) => void;

  @State(({ admin }: RootState) => admin.apiState.updateGroupUserAttribute)
  public updateAttributeApiState!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.verifyGroupUserAttribute)
  public verifyAttributeApiState!: ApiState;

  @State(({ admin }: RootState) => admin.apiState.getGroupUserAttributeById)
  public getUserAttributeWithGroupCount!: ApiState;

  @State(({ admin }: RootState) => admin.verifyGroupUserAttribute)
  public groupUserAttributeExisted!: boolean;

  @State(({ admin }: RootState) => admin.groupUserAttributeById)
  public userAttributeWithGroupCount!: GroupUserAttributeWithGroupCount;

  created(): void {
    this.getGroupAttributeWithGroupCount(this.attributeId);
  }

  public updateAttributeConfirmation(): void {
    this.$buefy.dialog.confirm({
      message: `<p class="buefy-dialog-title">Save Changes?</p><p class="buefy-dialog-content">Changes made will be applied automatically to all ${this.groupCount} groups associated with this attribute.</p>`,
      confirmText: 'Save',
      onConfirm: this.handleUpdate,
      canCancel: ['button'],
      cancelText: 'Cancel',
      onCancel: undefined
    });
  }

  public handleSubmisson(): void {
    if (this.groupCount) {
      this.updateAttributeConfirmation();
    } else {
      this.handleUpdate();
    }
  }

  public handleUpdate() {
    this.updateGroupUserAttribute({
      label: this.updatedName,
      groupUserAttribute: {
        id: this.attributeId,
        name: this.name,
        option: this.transformAttributeOption(),
        groupUserAttributeType: this.contentType as GroupUserAttributeType
      }
    });
  }

  /**
   * Transforms attribute option for form submission.
   * Only list type attributes' option has type of GroupUserAttributeOption,
   * every other attributes are supposed to be null.
   */
  public transformAttributeOption(): GroupUserAttributeOption | null {
    if (this.option === null) {
      return null;
    }

    return {
      ...this.option,
      value: this.getOptionValues() as string[]
    };
  }

  /**
   * Only capture strings with values.
   * As there might be empty string when the field is clear.
   * Empty option field should not block submission.
   */
  public getOptionValues(): string[] | null {
    if (this.option === null) {
      return null;
    }

    return this.option.value.filter((item) => item);
  }

  public onUseLabel(): void {
    this.isDuplicateAttributeFound = false;
  }

  public isNameUpdated(): boolean {
    if (this.updatedName === '') {
      return false;
    }

    return this.updatedName !== this.name;
  }

  public onCancelLabel(): void {
    this.updatedName = this.name;
  }

  public closeModal(): void {
    this.$emit('close');
  }

  /**
   * Function that ensures option values are unique.
   * Only validate option values when there IS a value.
   */
  public validateOption(itemIndex: number): ErrorTypeAndMessage {
    const value = this.option!.value[itemIndex];
    if (value === '') {
      return {
        type: '',
        message: ''
      };
    }

    const isDuplicated: boolean =
      this.option!.value.filter(
        (item) => item.toLowerCase() === value.toLowerCase()
      ).length > 1;

    if (isDuplicated) {
      return {
        type: 'is-danger',
        message: 'Option duplicated.'
      };
    }

    return {
      type: '',
      message: ''
    };
  }

  /**
   * Submission should be enabled when changes are made to:
   *
   * 1. attribute label name (all attribute types)
   * 2. list options changes (list type attributes only)
   */
  get enableSubmission(): boolean {
    if (this.userAttributeWithGroupCount === undefined) {
      return false;
    }

    if (this.verifyAttributeApiState.loading) {
      return false;
    }

    /**
     * This check is specific to non-list types only
     * because only list type has option.
     */
    if (this.userAttributeWithGroupCount.option === null) {
      return this.isNameUpdated() && this.isDuplicateAttributeFound === false;
    }

    /**
     * Make sure option has at least one value
     * TODO: add error message if complained.
     */
    if ((this.getOptionValues() as string[]).length === 0) {
      return false;
    }

    // check option values when attribute is of list type
    let isOptionValueUpdated: boolean = true;

    /**
     * Note:
     *
     * Do not lowercase when checking for duplication
     * so that user can still update character case.
     */
    const stateOptionValues: string = this.userAttributeWithGroupCount.option.value.join(
      ','
    );
    const currentOptionValues: string = (this.getOptionValues() as string[]).join(
      ','
    );
    isOptionValueUpdated = stateOptionValues !== currentOptionValues;

    return (
      (this.isNameUpdated() && this.isDuplicateAttributeFound === false) ||
      isOptionValueUpdated
    );
  }

  public removeOptionListItem(
    event: any,
    identifierClass: string,
    itemIndex: number
  ): void {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.option!.value.splice(itemIndex, 1);
    }
  }

  public addOptionListItem(): void {
    (this.option as GroupUserAttributeOption).value.push('');
  }

  @Watch('updatedName')
  public onLabelNameChange(updatedLabel: string): void {
    this.isDuplicateAttributeFound = false; // hide warning message on change

    if (updatedLabel === '') {
      return;
    }

    // Api should only be called when label name is changed (by character)
    if (updatedLabel.toLowerCase() !== this.name.toLowerCase()) {
      debouncer(600, this.verifyGroupUserAttribute, updatedLabel);
    }
  }

  @Watch('verifyAttributeApiState', { deep: true })
  public verifyAttributeApiCallback(state: ApiState): void {
    /**
     * This checking is for scenario where the user reverted the attribute name to original name
     * but the verify endpoint haven't return the response for previous attribute name check.
     */
    if (!this.isNameUpdated()) {
      this.isDuplicateAttributeFound = false;
      return;
    }

    if (state.success) {
      if (this.groupUserAttributeExisted) {
        this.isDuplicateAttributeFound = true;
      } else {
        this.isDuplicateAttributeFound = false;
      }
    }
  }

  @Watch('updateAttributeApiState', { deep: true })
  public updateAttributeApiCallback(state: ApiState): void {
    if (state.success) {
      Toast.open({
        message: 'Group user attribute updated successfully.',
        type: 'is-success',
        position: 'is-top'
      });

      this.$emit('attributeUpdateSuccess', {
        ...this.userAttributeWithGroupCount,
        name: this.updatedName,
        option: this.transformAttributeOption()
      } as GroupUserAttributeWithGroupCount);

      this.closeModal();
    }

    if (state.error) {
      Toast.open({
        message: 'Error updating attribute, please try again.',
        type: 'is-danger',
        position: 'is-top'
      });
    }
  }

  @Watch('getUserAttributeWithGroupCount', { deep: true })
  public getUserAttributeWithGroupCountState(state: ApiState): void {
    if (state.error) {
      Toast.open({
        message: 'Error getting attribute details. Please try again.',
        type: 'is-danger',
        position: 'is-top',
        duration: 10000
      });
    }
  }

  @Watch('userAttributeWithGroupCount', { deep: true })
  public storeAttributeData(attribute: GroupUserAttributeWithGroupCount): void {
    if (!attribute) {
      return;
    }

    this.name = attribute.name;
    this.updatedName = this.name;
    this.contentType = attribute.groupUserAttributeType;
    this.type = attribute.groupUserAttributeType.type;
    this.option = clone(attribute.option);
    this.groupCount = attribute.groupCount;
  }
}
