var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"60"}}),_vm._l((_vm.sortHeaders),function(item){return _c('SortableTableHeader',{key:item.placeholder,attrs:{"column":item.sortColumn,"sortOrder":_vm.getSortOrderOfColumn(item.sortColumn),"showArrow":item.sort && true,"sort":item.sort},on:{"sort":_vm.onClickHeaderColumn}},[_vm._v(" "+_vm._s(item.placeholder)+" ")])})],2)]},proxy:true},(_vm.items)?{key:"body",fn:function(){return _vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',[_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-licenses-deactivate_licenses'
              ) &&
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-licenses-update_licenses'
              ) &&
              !item.groupLicenseStatus.isLicenseExpired &&
              item.groupLicenseStatus.status === _vm.GroupLicenseStatusEnum.ACTIVE
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('deactivate', item)}}},[_vm._v(" Deactivate ")]):_vm._e(),(
              !item.groupLicenseStatus.isLicenseExpired &&
              item.groupLicenseStatus.status ===
                _vm.GroupLicenseStatusEnum.INACTIVE
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('activate', item)}}},[_vm._v(" Activate ")]):_vm._e(),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-licenses-update_licenses'
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v(" Edit ")]):_vm._e(),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.DELETE,
                'group_administration-licenses-delete_licenses'
              )
            )?_c('b-dropdown-item',{staticClass:"has-text-danger",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_vm._v(" Delete ")]):_vm._e()],1)],1),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.groupProductName))]),_c('td',[(item.groupDeletedAt === null)?[_c('router-link',{attrs:{"to":{
              name: 'admin-view-group',
              params: {
                groupId: String(item.groupId)
              }
            }}},[_vm._v(_vm._s(item.groupName))])]:[_vm._v(" "+_vm._s(item.groupName)+" (deleted on "+_vm._s(_vm.formatDateToReadable(item.groupDeletedAt))+") ")]],2),_c('td',[_vm._v(_vm._s(_vm.formatDateToReadable(item.endAt)))]),_c('td',[_vm._v(_vm._s(item.seats))]),_c('td',[_vm._v(_vm._s(item.groupLicenseStatus.status))])])})},proxy:true}:{key:"body",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('BaseLoading')],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No license found")])],1)])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }