































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import {
  Group,
  GroupType
} from '../../../../store/modules/admin/types/admin.types';
import { RootState } from '../../../../store/store';
import SelectGroupList from './select-group-list/SelectGroupList.vue';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';

@Component({
  components: {
    BasePaginatorHoc
  }
})
export default class SelectedGroupModal extends Vue {
  @Prop(Array) public selectedGroups!: Group[];
  @Prop(Array) public groupStored!: Group[];
  @Prop(Array) public groupsToDisable!: number[];

  public perPage: number = 50;
  public page: number = 1;
  public sortColumn: string = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filterParams: { selectedType: string; filterInput: string } = {
    selectedType: '',
    filterInput: ''
  };
  public expandGroupFilter: boolean = true;
  public selectedGroupIds: number[] = [];
  public totalCount: number = 0;

  public originalSelectedGroups: Group[] = [];
  public filteredGroups: Group[] = [];

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[];

  get typeOptions():
    | Array<{
        id: number;
        name: string;
        slug: string;
      }>
    | undefined {
    if (this.groupTypes) {
      const types = Object.values(this.groupTypes).map(
        (groupType: GroupType) => ({
          id: groupType.id,
          slug: groupType.name,
          name: groupType.name
        })
      );
      return [{ id: 0, name: 'All', slug: '' }, ...types];
    }
  }

  get SelectGroupList(): typeof SelectGroupList {
    return SelectGroupList;
  }

  public updateSelectedGroup(): void {
    this.$emit('updateSelectedGroup', this.selectedGroupIds);
    this.closeModal();
  }

  public handleSort(data: {
    sortColumn: string;
    sortOrder: 'ASC' | 'DESC';
  }): void {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    switch (this.sortColumn) {
      case 'name':
        if (this.sortOrder === 'ASC') {
          this.filteredGroups = this.originalSelectedGroups.sort((a, b) => {
            return ('' + a.name).localeCompare(b.name);
          });
        } else {
          this.filteredGroups = this.originalSelectedGroups.sort((a, b) => {
            return ('' + b.name).localeCompare(a.name);
          });
        }
        break;
      case 'group_type':
        if (this.sortOrder === 'ASC') {
          this.filteredGroups = this.originalSelectedGroups.sort((a, b) => {
            return ('' + a.types.name).localeCompare(b.types.name);
          });
        } else {
          this.filteredGroups = this.originalSelectedGroups.sort((a, b) => {
            return ('' + b.types.name).localeCompare(a.types.name);
          });
        }
        break;
      default:
        break;
    }
    this.handlePaginator({ perPage: this.perPage, page: this.page });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }): Promise<void> {
    const { selectedType, filterInput } = this.filterParams;
    const filteredSelectedGroups: Group[] = this.originalSelectedGroups.filter(
      (group) =>
        group.name.toLowerCase().includes(filterInput.toLowerCase()) &&
        (group.types.name.toLowerCase() === selectedType.toLowerCase() ||
          selectedType === '') &&
        group.nlevel === 1
    );
    const offset: number = perPage * page - perPage;
    this.filteredGroups = this.originalSelectedGroups
      .filter((group) => group.nlevel === 1)
      .slice(offset, offset + perPage);
    this.filteredGroups.push(
      ...this.originalSelectedGroups.filter((group) => group.nlevel > 1)
    );
    this.page = page;
    this.totalCount = filteredSelectedGroups.length;
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public mounted(): void {
    this.selectedGroupIds = this.selectedGroups.map((group) => group.id);
    this.originalSelectedGroups = this.filterGroupByAncestors(this.groupStored);
    this.handlePaginator({ perPage: this.perPage, page: this.page });
    this.totalCount = this.selectedGroups.length;
  }

  public filterGroupByAncestors(groups: Group[], selectedGroups?: Group[]) {
    if (this.selectedGroupIds.length === 0) {
      return [];
    }

    let groupWithAncestors: Group[] = [];
    (selectedGroups || this.selectedGroups).forEach((group) => {
      const ancestorPath: string =
        group.id === +group.path ? '' : group.path.split('' + group.id)[0];
      groupWithAncestors = [
        ...groupWithAncestors,
        ...groups
          // Avoid path like 550.551 matched with 5
          .filter((item) => (item.path + '.').startsWith(group.path + '.'))
          .map((item) => ({
            ...item,
            path:
              ancestorPath === '' ? item.path : item.path.split(ancestorPath)[1]
          }))
          .map((item) => ({ ...item, nlevel: item.path.split('.').length }))
      ];
    });
    return this.getUniqGroups(groupWithAncestors);
  }

  public getUniqGroups(groups: Group[]): Group[] {
    return [...new Map(groups.map((group) => [group.id, group])).values()];
  }

  @Watch('filterParams', { deep: true })
  @Debounce(600)
  public onChange(): void {
    this.handlePaginator({ perPage: this.perPage, page: 1 });
  }
}
