

































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeOption,
  GroupUserAttributeType,
  GroupUserAttributeTypeStatus
} from '../../../../jbi-shared/types/myjbi-group.types';
import { Debounce } from '../../../../jbi-shared/util/debounce.vue-decorator';
import { isTruthy } from '../../../../jbi-shared/util/watcher.vue-decorator';
import { RootState } from '../../../../store/store';

export interface DefineFieldParam {
  contentType: GroupUserAttributeType;
  label: string;
  selectedTypeOption: GroupUserAttributeOption | null;
}

@Component
export default class DefineFieldStep extends Vue {
  @Action('admin/getGroupUserAttributeTypes')
  public getGroupUserAttributeTypes!: () => void;

  @Action('admin/verifyGroupUserAttribute')
  public verifyGroupUserAttribute!: (attributeLabel: string) => void;

  @State(({ admin }: RootState) => admin.groupUserAttributeTypes)
  public userAttributeTypes!: GroupUserAttributeType[] | undefined;

  @State((state: RootState) => state.admin.verifyGroupUserAttribute)
  public getVerifyGroupUserAttribute!: boolean;

  @State(
    (state: RootState) => state.admin.apiState.verifyGroupUserAttribute.success
  )
  public getVerifyGroupUserAttributeSuccess!: boolean;

  public params: DefineFieldParam = {
    contentType: {
      id: 0,
      type: '',
      option: null,
      status: GroupUserAttributeTypeStatus.ACTIVE
    },
    label: '',
    selectedTypeOption: null
  };
  public isDuplicateAttributeFound: boolean = false;
  public attributeOptionValues: string[] = Array(2);
  public attributeOptionErrorIndexes: number[] = [];

  public getAttributeOptionValues(): string[] {
    return this.attributeOptionValues.filter((value) => value.length);
  }

  public mounted(): void {
    this.getGroupUserAttributeTypes();
  }

  @Debounce(800)
  public validateLabelAndType(): void {
    const { contentType, label } = this.params;

    if (label.length >= 1 && contentType.id !== 0) {
      this.verifyGroupUserAttribute(label);
    } else {
      this.isDuplicateAttributeFound = false;
    }
  }

  public removeOptionListItem(
    event: any,
    identifierClass: string,
    itemIndex: number
  ): void {
    if (event.target.parentElement.className.includes(identifierClass)) {
      this.attributeOptionValues.splice(itemIndex, 1);
      this.checkDuplicateAttributeOptionValues();
    }
  }

  public checkDuplicateAttributeOptionValues(): void {
    this.attributeOptionErrorIndexes = [];
    this.attributeOptionValues.forEach((item, index) => {
      if (item.trim() && this.attributeOptionValues.indexOf(item) !== index) {
        this.attributeOptionErrorIndexes.push(index);
      }
    });
  }

  public onUseLabel(): void {
    this.isDuplicateAttributeFound = false;
    this.assignParams();
    if (this.params.label !== '') {
      this.$emit('isDisableNext', false);
    }
  }

  public validateListAttributeType(): boolean {
    if (this.params.contentType.type !== 'list') {
      return false;
    }
    return (
      this.params.selectedTypeOption === null ||
      this.attributeOptionErrorIndexes.length > 0 ||
      !this.getAttributeOptionValues().length
    );
  }

  public assignParams(): void {
    if (
      this.params.contentType.type === 'list' &&
      this.params.selectedTypeOption
    ) {
      Object.assign(
        this.params.selectedTypeOption.value,
        this.getAttributeOptionValues()
      );
    }
    this.$emit('updateParams', this.params);
  }

  public addListItemOption(): void {
    this.attributeOptionValues.push('');
  }

  @Watch('params.label')
  @Watch('attributeOptionValues', { deep: true })
  public onLabelNameChange(): void {
    this.$emit('isDisableNext', true);
    this.validateLabelAndType();
  }

  @Watch('getVerifyGroupUserAttributeSuccess')
  @isTruthy
  public watchVerifyGroupUserAttributeSuccess(): void {
    if (this.getVerifyGroupUserAttribute) {
      this.isDuplicateAttributeFound = true;
    } else {
      this.isDuplicateAttributeFound = false;
      this.assignParams();
    }
    this.$emit(
      'isDisableNext',
      this.validateListAttributeType() || this.getVerifyGroupUserAttribute
    );
  }
}
