var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{attrs:{"title":"Group Administration"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h1',[_vm._v("Group Administration")])]},proxy:true}])},[_c('b-tabs',{class:_vm.$style.tabContainer,attrs:{"animated":false},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-groups-read_groups'
        )
      )?_c('b-tab-item',{attrs:{"label":"Groups","disabled":_vm.disableTab}},[_c('GroupTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-licenses-read_licenses'
        )
      )?_c('b-tab-item',{attrs:{"label":"License","disabled":_vm.disableTab}},[_c('LicenseTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-products-read_products'
        )
      )?_c('b-tab-item',{attrs:{"label":"Products","disabled":_vm.disableTab}},[_c('ProductTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-group_types-read_group_types'
        )
      )?_c('b-tab-item',{attrs:{"label":"Group Type","disabled":_vm.disableTab}},[_c('GroupTypeTab',{key:_vm.groupTypeTabKey,on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-group_templates-read_group_templates'
        )
      )?_c('b-tab-item',{attrs:{"label":"Group Templates","disabled":_vm.disableTab}},[_c('GroupTemplateTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
        )
      )?_c('b-tab-item',{attrs:{"label":"User Attributes","disabled":_vm.disableTab}},[_c('AttributeTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e(),(
        _vm.checkForPermissions(
          _vm.PermissionsMatrixActionsEnum.READ,
          'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
        )
      )?_c('b-tab-item',{attrs:{"label":"User Templates","disabled":_vm.disableTab}},[_c('UserAttributesTemplateTab',{on:{"activateTab":_vm.activateTab}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }