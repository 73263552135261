

































































































import Container from '@/components/Container.vue';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import GroupTab from '@/views/AdminGroupManagement/Group.tab.vue';
import GroupTemplateTab from '@/views/AdminGroupManagement/GroupTemplate.tab.vue';
import GroupTypeTab from '@/views/AdminGroupManagement/GroupType.tab.vue';
import LicenseTab from '@/views/AdminGroupManagement/License.tab.vue';
import ProductTab from '@/views/AdminGroupManagement/Product.tab.vue';
import AttributeTab from '@/views/AdminUserManagement/Attribute.tab.vue';
import UserAttributesTemplateTab from '@/views/AdminUserManagement/UserAttributesTemplate.tab.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { State } from 'vuex-class';
import { RootState } from '@/store/store';

interface TabData<T> {
  [key: string]: T;
}

let tabs: Array<TabData<string>> = [
  {
    tab: 'Groups',
    groupname: '',
    limit: '50',
    page: '1',
    sortColumn: 'username',
    sortOrder: 'ASC'
  },
  {
    tab: 'License',
    licenseName: '',
    groupProductsName: '',
    groupsName: '',
    status: '',
    limit: '50',
    page: '1',
    sortColumn: 'createdAt',
    sortOrder: 'ASC'
  },
  {
    tab: 'Products',
    productName: '',
    limit: '50',
    page: '1',
    sortColumn: 'title',
    sortOrder: 'ASC'
  },
  {
    tab: 'GroupType',
    limit: '50',
    page: '1',
    sortColumn: 'groupType',
    sortOrder: 'ASC'
  },
  {
    tab: 'GroupTemplates',
    templateName: '',
    limit: '50',
    page: '1',
    sortColumn: 'templateName',
    sortOrder: 'ASC'
  },
  {
    tab: 'Attributes',
    email: '',
    type: '',
    limit: '50',
    page: '1',
    sortColumn: 'attributeName',
    sortOrder: 'ASC'
  },
  {
    tab: 'UserAttributesTemplate',
    templateName: '',
    limit: '50',
    page: '1',
    sortColumn: 'templateName',
    sortOrder: 'ASC',
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    selectedUserType: 'all',
    selectedStatus: ''
  }
];

const tabMapModule: Array<{
  tab: string;
  action: PermissionsMatrixActionsEnum;
  module: string;
}> = [
  {
    tab: 'Groups',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'group_administration-groups-read_groups'
  },
  {
    tab: 'License',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'group_administration-licenses-read_licenses'
  },
  {
    tab: 'Products',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'group_administration-products-read_products'
  },
  {
    tab: 'GroupType',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'group_administration-group_types-read_group_types'
  },
  {
    tab: 'GroupTemplates',
    action: PermissionsMatrixActionsEnum.READ,
    module: 'group_administration-group_templates-read_group_templates'
  },
  {
    tab: 'Attributes',
    action: PermissionsMatrixActionsEnum.READ,
    module:
      'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
  },
  {
    tab: 'UserAttributesTemplate',
    action: PermissionsMatrixActionsEnum.READ,
    module:
      'group_administration-groups-update_groups-update_user_attributes-read_existing_user_attributes'
  }
];

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  methods: { isUserAllowed },
  components: {
    Container,
    GroupTab,
    LicenseTab,
    ProductTab,
    GroupTypeTab,
    GroupTemplateTab,
    AttributeTab,
    UserAttributesTemplateTab
  }
})
export default class AdminGroupManagement extends Vue {
  public activeTab = 0;
  public disableTab = true;
  public groupTypeTabKey: number = Math.floor(Math.random() * 999);

  @State((state: RootState) => state.rbac.groupTypesUserHasAccessTo)
  groupTypesUserHasAccessTo!: string[];

  @State((state: RootState) => state.rbac.groupsUserHasAccessTo)
  groupsUserHasAccessTo!: number[];

  public mounted() {
    this.filterTabsBasedOnAvailability();

    if (this.$route.query.tab) {
      // const index = tabs.findIndex((res) => res.tab === this.$route.query.tab);
      const index = this.getNextAccessibleTabIndex();
      if (index >= 0) {
        this.activeTab = +index;
        this.disableTab = false;
      }
    }
  }

  filterTabsBasedOnAvailability() {
    for (const tab of tabMapModule) {
      if (!this.checkForPermissions(tab.action, tab.module)) {
        tabs = tabs.filter((item) => item.tab !== tab.tab);
      }
    }
  }

  getNextAccessibleTabIndex(): number {
    const targetTab = tabMapModule.find(
      (item) => item.tab === this.$route.query.tab
    );
    if (
      targetTab &&
      this.checkForPermissions(targetTab.action, targetTab.module)
    ) {
      return tabs.findIndex((res) => res.tab === this.$route.query.tab);
    } else {
      for (const tab of tabMapModule) {
        if (this.checkForPermissions(tab.action, tab.module)) {
          const index = tabs.findIndex((res) => res.tab === tab.tab);
          this.onTabChange(index);
          return index;
        }
      }
    }

    return 0;
  }

  public activateTab() {
    this.disableTab = false;
  }

  /**
   * This helper function was made to keep the template clean. Group type and
   * group IDs remain the same regardless of where isUserAllowed is used in the
   * component.
   * @param action
   * @param module
   * @param skipImplicitCheck
   */
  public checkForPermissions(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupTypesUserHasAccessTo
      ? this.groupTypesUserHasAccessTo[0]
      : undefined;
    const groupId = this.groupsUserHasAccessTo
      ? this.groupsUserHasAccessTo[0]
      : undefined;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      groupId,
      undefined,
      skipImplicitCheck
    );
  }

  @Watch('activeTab')
  public onTabChange(value: number) {
    this.disableTab = false;

    if (this.$route.query.tab === tabs[value].tab) {
      return;
    }
    switch (tabs[value].tab) {
      case 'Groups':
      case 'License':
      case 'Products':
      case 'GroupTemplates':
        break;
      case 'GroupType':
        this.groupTypeTabKey += 1;
        break;
      default:
    }
    this.$router.replace({ query: undefined });

    this.$router.push({
      query: {
        ...tabs[value]
      }
    });
  }
}
