var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{class:[_vm.$style.tableHeader]}),_vm._l((_vm.sortHeaders),function(item){return _c('SortableTableHeader',{key:item.placeholder,attrs:{"column":item.sortColumn,"sortOrder":_vm.getSortOrderOfColumn(item.sortColumn),"showArrow":item.sort && true,"sort":item.sort},on:{"sort":_vm.onClickHeaderColumn}},[_vm._v(" "+_vm._s(item.placeholder)+" ")])})],2)]},proxy:true},(_vm.getGroupUserAttributeListSuccess)?{key:"body",fn:function(){return _vm._l((_vm.items),function(attribute,index){return _c('tr',{key:("email(" + index + ")")},[_c('td',[_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[(
              _vm.checkForPermissions(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-groups-update_groups-update_user_attributes-edit_existing_user_attributes',
                true
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onEditAttribute(attribute)}}},[_vm._v(" Edit ")]):_vm._e(),(
              _vm.checkForPermissions(
                _vm.PermissionsMatrixActionsEnum.CREATE,
                'group_administration-groups-update_groups-update_user_attributes-create_existing_user_attributes',
                true
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onAddAttribute(attribute)}}},[_vm._v(" Add to Group(s) ")]):_vm._e(),(
              _vm.checkForPermissions(
                _vm.PermissionsMatrixActionsEnum.DELETE,
                'group_administration-groups-update_groups-update_user_attributes-delete_added_user_attributes',
                true
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onRemoveGroups(attribute)}}},[_vm._v(" Remove from Group(s) ")]):_vm._e(),(
              _vm.checkForPermissions(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-groups-update_groups-update_user_attributes-edit_existing_user_attributes',
                true
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onManageSettings(attribute)}}},[_vm._v(" Manage Settings ")]):_vm._e(),(
              _vm.checkForPermissions(
                _vm.PermissionsMatrixActionsEnum.DELETE,
                'group_administration-groups-update_groups-update_user_attributes-delete_existing_user_attributes',
                true
              )
            )?_c('b-dropdown-item',{staticClass:"has-text-danger",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.onDeleteAttribute(attribute)}}},[_vm._v(" Delete ")]):_vm._e()],1)],1),_c('td',[_vm._v(_vm._s(attribute.name || '-'))]),_c('td',{class:_vm.$style.capitalize},[_vm._v(_vm._s(attribute.type || '-'))]),_c('td',[_vm._v(_vm._s(attribute.groupCount || 0))])])})},proxy:true}:{key:"body",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('BaseLoading')],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',[(_vm.getGroupUserAttributeListSuccess)?_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No attribute found")])],1):_vm._e()])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }