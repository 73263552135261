





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Group } from '../../../../store/modules/admin/types/admin.types';
import SelectGroupDropdown from './SelectGroupDropdown.vue';

@Component({
  components: {
    SelectGroupDropdown
  }
})
export default class ManageSettingStep extends Vue {
  @Prop(Array) selectedGroups!: Group[];
  @Prop(Array) groupStored!: Group[];
  @Prop(Array) public initialSelectedGroups!: Group[];
  @Prop(Array) public selectedLockGroups!: number[];
  @Prop(Array) public selectedRequiredGroups!: number[];
  @Prop(String) public attributeLabel!: string;

  public selectedRequiredGroupIds: number[] = [];
  public selectedLockGroupIds: number[] = [];
  public groupsToDisable: number[] = [];

  public isSelectSameGroup: boolean = false;

  public updateRequiredGroupIds(selectedGroupIds: number[]): void {
    this.selectedRequiredGroupIds = selectedGroupIds;
    this.$emit('onSelectedRequiredGroupIds', selectedGroupIds);
  }

  public updateLockGroupIds(selectedGroupIds: number[]): void {
    this.selectedLockGroupIds = selectedGroupIds;
    this.$emit('onSelectedLockGroupIds', selectedGroupIds);
  }

  public onSelectSameCheckboxChange(value: boolean): void {
    this.isSelectSameGroup = value;
    if (this.isSelectSameGroup) {
      this.selectedLockGroupIds = this.selectedRequiredGroupIds;
    }
  }

  @Watch('selectedRequiredGroupIds')
  @Watch('selectedLockGroupIds')
  public onSelectSameGroupChange(): void {
    if (
      [...this.selectedRequiredGroupIds].sort().join(',') !==
      [...this.selectedLockGroupIds].sort().join(',')
    ) {
      this.isSelectSameGroup = false;
    }
  }

  @Watch('initialSelectedGroups')
  public onInitialSelectedGroupsUpdate(): void {
    if (!this.initialSelectedGroups) {
      return;
    }
    const initialSelectedGroupIds = this.initialSelectedGroups?.map(
      (group) => group.id
    );
    this.selectedLockGroupIds = this.selectedLockGroups;
    this.groupsToDisable = [...initialSelectedGroupIds];
  }
}
