




























































































import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

import { RootState } from '../../../../store/store';
import BaseTable from '../../../../components/BaseTable.vue';
import BaseLoading from '../../../../components/base/BaseLoading.vue';
import BasePaginator from '../../../../components/base/BasePagination.vue';
import BasePagination from '../../../../components/base/BasePagination.vue';
import { SortOrder, ApiState } from '../../../../store/types/general.types';
import { PaginationMixin } from '../../../../components/base/pagination.mixin';
import TableActionDropdown from '../../../../components/TableActionDropdown.vue';
import SortableTableHeader from '../../../../components/SortableTableHeader.vue';
import ConfirmDeleteUserAttributeTemplateModal from './ConfirmDeleteTemplateModal.vue';
import { TemplateAttributeDisplayCount } from '../../../../store/modules/admin/types/group-level-attribute.types';
import { GroupUserAttributeTemplateUpStream } from '../../../../store/modules/admin/types/group-user-attribute-template.types';

/**
 * @description
 * List of user attribute templates in a paginated table.
 */
@Component({
  components: {
    BaseTable,
    BaseLoading,
    BasePaginator,
    BasePagination,
    SortableTableHeader,
    TableActionDropdown
  }
})
export default class UserAttributeTemplateList extends mixins(PaginationMixin) {
  @Prop() items!: GroupUserAttributeTemplateUpStream[];
  @Prop() isLoading!: boolean;
  @Prop() totalNumberOfPage!: number;
  @Prop() totalCount!: number;
  @Prop() isFirstPage!: boolean;
  @Prop() isLastPage!: boolean;
  @Prop() startItemIndex!: number;
  @Prop() endItemIndex!: number;
  @Prop() sortOrder!: SortOrder;

  @State(
    ({ admin }: RootState) => admin.apiState.getGroupUserAttributeTemplates
  )
  getUserAttributeTemplatesApiState!: ApiState;

  get attributeDisplayCount(): number {
    return TemplateAttributeDisplayCount;
  }

  isViewMoreVisible(template: GroupUserAttributeTemplateUpStream): boolean {
    return template.attributes.length > this.attributeDisplayCount;
  }

  getViewMoreText(template: GroupUserAttributeTemplateUpStream): string {
    return `+${template.attributes.length - this.attributeDisplayCount} more`;
  }

  onColumnHeaderClick(columnName: string): void {
    const sortOrder =
      this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    this.$emit('sort', sortOrder);
  }

  confirmTemplateDelete(template: GroupUserAttributeTemplateUpStream): void {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDeleteUserAttributeTemplateModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        templateName: template.name
      },
      events: {
        confirm: (userConfirm: boolean) => {
          if (userConfirm) {
            this.$emit('deleteTemplate', template.id);
          }
        }
      }
    });
  }

  openTemplateView(templateId: number): void {
    this.$emit('viewTemplate', templateId);
  }

  handleTemplateEdit(templateId: number): void {
    this.$emit('editTemplate', templateId);
  }
}
