





























































import { Component, Vue, Prop, Watch, PropSync } from 'vue-property-decorator';
import { MyjbiGroupType } from '../../../../../jbi-shared/types/myjbi-group.types';
import { Group } from '../../../../../store/modules/admin/types/admin.types';

@Component({
  components: {},
  name: 'SelectGroupListRow'
})
export default class SelectGroupListRow extends Vue {
  @PropSync('selectedGroupIds', { type: Array })
  public syncedSelectedGroupIds!: number[];
  @Prop(Object) public group!: Group;
  @Prop(Function) public getChildren!: (group: Group) => Group[];
  @Prop({ type: Number, default: 1 }) public level!: number;
  @Prop(Boolean) public expandGroupFilter!: boolean;
  @Prop(Boolean) public isParentSelected!: boolean;
  @Prop(Array) public groupsToDisable!: number[];

  public isSelected: boolean = false;

  public expanded: boolean = false;
  public isFirstTimeSelected: boolean = false;

  get descendants(): Group[] {
    return this.getChildren(this.group);
  }

  get noSubGroup(): boolean {
    return !this.descendants.length;
  }

  get MyjbiGroupType(): typeof MyjbiGroupType {
    return MyjbiGroupType;
  }

  public isGroupDisabled(): boolean {
    return (
      this.isParentSelected || this.groupsToDisable?.includes(this.group?.id)
    );
  }

  public toggleGroup(value: boolean): void {
    this.isSelected = value;
    if (value) {
      this.syncedSelectedGroupIds = [
        ...this.syncedSelectedGroupIds,
        this.group.id
      ];
      return;
    }
    this.removeCurrentGroup();
  }

  public removeCurrentGroup(): void {
    this.syncedSelectedGroupIds = this.syncedSelectedGroupIds.filter(
      (id) => id !== this.group.id
    );
  }

  public mounted(): void {
    if (
      this.syncedSelectedGroupIds.indexOf(this.group.id) !== -1 &&
      !this.isParentSelected
    ) {
      this.isSelected = true;
    }
  }

  @Watch('expandGroupFilter', { immediate: true })
  onOpenGroupsExpandedChanged(): void {
    this.expanded = this.expandGroupFilter;
  }

  @Watch('syncedSelectedGroupIds', { immediate: true })
  onSelectedGroupUpdate(groupIds: number[], oldValue: number[]): void {
    this.isSelected =
      groupIds.includes(this.group.id) && !this.isParentSelected;
    if (
      oldValue &&
      (this.isSelected ||
        this.getChildren(this.group).filter((children) =>
          this.syncedSelectedGroupIds.includes(children.id)
        ).length)
    ) {
      this.expanded = true;
      this.isFirstTimeSelected = false;
    }
  }

  @Watch('isParentSelected', { immediate: true })
  onParentSelected(): void {
    this.isSelected = this.isParentSelected;
    if (this.isParentSelected) {
      this.removeCurrentGroup();
    }
  }
}
