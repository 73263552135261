






























import { Component, Vue, Prop } from 'vue-property-decorator';

import { GroupUserAttributeOption } from '../../../../jbi-shared/types/myjbi-group.types';
import {
  GroupUserAttribute,
  Nullable
} from '../../../../store/modules/admin/types/group-user-attribute-template.types';

@Component({})
export default class ListAttributeOption extends Vue {
  @Prop() attribute!: GroupUserAttribute;

  listConfig: Nullable<GroupUserAttributeOption> = this.attribute.option;
}
